/*Default colors and styling applied to all the sections*/
:root{
    --redColor: #DC0000;
    --maroonColor: #850000;
    --creamColor: #FFDB89;
    --lightCreamColor: #FFF6C3;
  
  
    --darkColor: #0A2647;
    --hoverLightColor: #144272;
    --primaryColor: hsl(199,100%,33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, #2C74B3, #144272);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 11%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(163, 163, 163);
    --inputColor: rgb(239, 239, 239);
    --cardBG: rgb(225, 225, 235);
  
    --blueColor: #22577a;
    --tealColor: #38a3a5;
    --greenColor: #57cc99;
    --lightGreenColor: #80ed99;
    --lightColor: #c7f9cc;
}


.tpoPage{
    width: 100%;
    padding: 1rem 2rem;

    .pageTitle{
        font-size: 20px;
        font-weight: 600;
    }
    hr{
        margin-top: 10px;
        margin-bottom: 10px;
    }


    .eachPlacementDiv{
        width: 100%;

        .companyTitle{
            text-align: left;
            font-size: 15px;
            margin: 1rem 0;
        }

        .courseTableDiv{
            width: 100%;
            border: 1px solid black;
            border-bottom: 0;
            margin-bottom: 1rem;


            .table{
                width: 100%;
                display: flex;
                margin-bottom: 0;

                .block{
                    text-align: center;
                    padding: .5rem 0;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;

                    .blockContent{
                        font-size: 10px;
                        margin-bottom: 0;
                        font-weight: 500;
                    }

                    
                    &:nth-child(1){
                        width: 10%;
                    }
                    &:nth-child(2){
                        width: 30%;
                    }
                    &:nth-child(3){
                        width: 60%;
                    }

                }
            }

            .second{
                background: var(--maroonColor);
                color: white;
                .block{
                    .blockContent{
                        font-size: 10px;
                        font-weight: 500;
                    }
                }

            }
        }
    }





}

@media screen and (min-width: 600px) {
    .tpoPage{
        padding: 2rem 3rem;

        .pageTitle{
            font-size: 25px;
        }

        .eachPlacementDiv{

            .companyTitle{
                font-size: 18px;
                margin: 2rem 0 1.5rem;
            }

            .courseTableDiv{
                .table{
                    .block{
                        .blockContent{
                            font-size: 12px;
                        }
                    }
                }
            }
        }




    }
}

@media screen and (min-width: 840px) {
    .tpoPage{
        padding: 2rem 4rem;

        .pageTitle{
            font-size: 25px;
        }

        .eachPlacementDiv{

            .companyTitle{
                font-size: 20px;
                margin: 1.5rem 0;
            }

            .courseTableDiv{
                .table{
                    .block{
                        .blockContent{
                            font-size: 15px;
                        }
                    }
                }
            }
        }




    }
}

@media screen and (min-width: 1024px) {
    .tpoPage{
        padding: 2rem 6rem;

        .pageTitle{
            font-size: 25px;
        }

        .eachPlacementDiv{

            .companyTitle{
                font-size: 20px;
                margin: 2rem 0 1.5rem;
            }
            .courseTableDiv{
                .table{
                    .block{
                        .blockContent{
                            font-size: 15px;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (min-width: 1440px) {
    .tpoPage{
        padding: 2rem 10rem;

        hr{
            margin-bottom: 1.5rem;
        }

        .pageTitle{
            font-size: 35px;
            margin-bottom: 2rem;
        }

        .eachPlacementDiv{

            .companyTitle{
                font-size: 25px;
                margin: 3rem 0 1.5rem;
            }
            .courseTableDiv{
                .table{
                    .block{
                        .blockContent{
                            font-size: 15px;
                        }
                    }
                }
            }
        }

    }
}
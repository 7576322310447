

.accreditionPage{
    width: 100%;
    padding: 1rem 2rem;

    .pageTitle{
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    .tabsGridDiv{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding: 1rem 0;

        .eachTab{
            width: 100%;
            padding: 1rem;
            gap: 1rem;
            
            .imgDiv{
                margin: auto;
                width: 80%;
                height: 20vh;
                overflow: hidden;

                .tabImg{
                    width: 100%;
                    height: 100%;
                }
            }

            .textArea{
                width: 100%;
                text-align: justify;
                margin-top: 1rem;

                .text{
                    font-size: 15px;

                    span{
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .accreditionPage{
        padding: 1.5rem 3rem;

        .pageTitle{
            font-size: 1.5rem;
        }

        .tabsGridDiv{
            grid-template-columns: repeat(2, 1fr);

            .eachTab{
                .imgDiv{
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 840px) {
    .accreditionPage{
        padding: 1.5rem 4rem;

        .pageTitle{
            font-size: 1.5rem;
        }

        .tabsGridDiv{
            grid-template-columns: repeat(2, 1fr);

            .eachTab{
                .imgDiv{
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .accreditionPage{
        padding: 1.5rem 6rem;

        .pageTitle{
            font-size: 1.5rem;
        }

        .tabsGridDiv{
            grid-template-columns: repeat(3, 1fr);

            .eachTab{
                .imgDiv{
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .accreditionPage{
        padding: 1.5rem 10rem;

        .pageTitle{
            font-size: 2rem;
            margin-bottom: 2rem;
        }

        .tabsGridDiv{
            grid-template-columns: repeat(4, 1fr);
            gap: 1rem;

            .eachTab{
                .imgDiv{
                    width: 100%;
                }
            }
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/*Default colors and styling applied to all the sections*/
:root{
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;


  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);

  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

html{
  scroll-behavior: smooth;
}

a{
  text-decoration: none;
}

li{
  list-style: none;
}

/* .section{
  padding: 4rem 0 2rem;
} */

.container{
  padding-left: 1.5rem;
  padding-right: 1.5em;
}

.icon{
  font-size: 2rem;
  cursor: pointer;
}

.flex{
  display: flex;
  align-items: center;
}

.grid{
  display: grid;
  align-items: center;
}

.btn{
  padding: .6rem 1.5rem;
  background: var(--gradientColor);
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn a{
  color: var(--whiteColor);
  font-weight: 500;
}

.btn:hover{
  background: var(--secondaryColor);
}

.loginBtn{
  padding: 1rem;
  gap: 10px;
  background: #5985E5;
  border-radius: 8px;
}

img,video{
  width: 100%;
  height: auto;
}

input{
  font-size: 100%;
}

body{
  background: var(--whiteColor);
}

/*Media querries*/
@media screen and (min-width: 1240px){
  .container{
    /* width: 80% !important; */
    margin: auto !important;
  }

  .section{
    padding: 6rem 0 4rem;
  }
}

@media screen and (min-width: 2560px) {
  body{
    zoom: 1.7;
  }
}

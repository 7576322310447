/*Default colors and styling applied to all the sections*/
:root{
    --redColor: #DC0000;
    --maroonColor: #850000;
    --creamColor: #FFDB89;
    --lightCreamColor: #FFF6C3;
  
  
    --darkColor: #0A2647;
    --hoverLightColor: #144272;
    --primaryColor: hsl(199,100%,33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, #2C74B3, #144272);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 11%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(163, 163, 163);
    --inputColor: rgb(239, 239, 239);
    --cardBG: rgb(225, 225, 235);
  
    --blueColor: #22577a;
    --tealColor: #38a3a5;
    --greenColor: #57cc99;
    --lightGreenColor: #80ed99;
    --lightColor: #c7f9cc;
}


.coursesBase{
    width: 100%;
    padding: 1rem 2rem;

    .sectionTitle{
        font-size: 20px;
        font-weight: 600;
    }
    hr{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .courseContentDiv{
        margin-bottom: 1rem;
        line-height: 1;
        .courseTitle{
            font-size: 15px;
            font-weight: 600;

        }
        p{
            font-size: 12px;
            font-weight: 500;

            span{
                font-weight: 600;
            }
        }
    }

    .courseTableDiv{
        width: 100%;
        border: 1px solid black;
        border-bottom: 0;
        margin-bottom: 1rem;


        .table{
            width: 100%;
            display: flex;
            margin-bottom: 0;

            .block{
                text-align: center;
                padding: .5rem 0;
                border-right: 1px solid black;
                border-bottom: 1px solid black;

                .blockContent{
                    font-size: 10px;
                    margin-bottom: 0;
                    font-weight: 500;
                }

                
                &:nth-child(1){
                    width: 10%;
                }
                &:nth-child(2){
                    width: 25%;
                }
                &:nth-child(3){
                    width: 10%;
                }
                &:nth-child(4){
                    width: 55%;
                    border-right: 0;
                }

            }
        }

        .second{
            background: var(--maroonColor);
            color: white;
            .block{
                .blockContent{
                    font-size: 10px;
                    font-weight: 500;
                }
            }

        }
    }

    .subjectList{
        padding: 0 1rem;
        p{
            font-size: 10px;
            font-weight: 500;
            span{
                font-weight: 600;
                color: blue;
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .coursesBase{
        padding: 2rem 3rem;

        .sectionTitle{
            font-size: 20px;
        }

        .courseContentDiv{
            .courseTitle{
                font-size: 15px;
            }
            p{
                font-size: 12px;
            }
        }

        .courseTableDiv{
            .table{
                .block{
                    .blockContent{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 840px) {
    .coursesBase{
        padding: 2rem 4rem;

        .sectionTitle{
            font-size: 25px;
        }

        .courseContentDiv{
            .courseTitle{
                font-size: 20px;
            }
            p{
                font-size: 15px;
            }
        }

        .courseTableDiv{
            .table{
                .block{
                    .blockContent{
                        font-size: 15px;
                    }
                }
            }
        }

        .subjectList{
            p{
                font-size: 12px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .coursesBase{
        padding: 2rem 6rem;

        .sectionTitle{
            font-size: 25px;
        }

        .courseContentDiv{
            .courseTitle{
                font-size: 20px;
            }
            p{
                font-size: 15px;
            }
        }

        .courseTableDiv{
            .table{
                .block{
                    .blockContent{
                        font-size: 15px;
                    }
                }
            }
        }

        .subjectList{
            margin-top: 2rem;
            p{
                font-size: 15px;
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .coursesBase{
        padding: 2rem 10rem;

        .sectionTitle{
            font-size: 35px;
        }

        .courseContentDiv{
            margin-bottom: 2rem;
            .courseTitle{
                font-size: 25px;
            }
            p{
                font-size: 18px;
            }
        }

        .courseTableDiv{
            .table{
                .block{
                    .blockContent{
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
/*Default colors and styling applied to all the sections*/
:root {
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;
  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);
  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.disclosurePage {
  width: 100%;
  padding: 2rem;
  height: 75vh;
}
.disclosurePage .pageTitle {
  font-size: 20px;
  font-weight: 600;
  color: var(--maroonColor);
}
.disclosurePage .content {
  margin-top: 5rem;
  font-size: 15px;
}
.disclosurePage .content .icon {
  font-size: 20px;
  color: var(--maroonColor);
}
.disclosurePage .downloadBtn {
  padding: 0.5rem 1rem;
  background: var(--maroonColor);
  border: none;
  border-radius: 5px;
}
.disclosurePage .downloadBtn a {
  text-decoration: none;
  font-size: 12px;
  color: white;
}
.disclosurePage .downloadBtn a .icon {
  font-size: 20px;
  margin-right: 5px;
  margin-top: -5%;
}

@media screen and (min-width: 600px) {
  .disclosurePage {
    padding: 2rem 3rem;
  }
  .disclosurePage .pageTitle {
    font-size: 25px;
  }
  .disclosurePage .content {
    font-size: 16px;
  }
  .disclosurePage .downloadBtn a {
    font-size: 13px;
  }
  .disclosurePage .downloadBtn a .icon {
    font-size: 22px;
    margin-top: -2%;
  }
}
@media screen and (min-width: 840px) {
  .disclosurePage {
    padding: 2rem 4rem;
  }
  .disclosurePage .pageTitle {
    font-size: 30px;
  }
  .disclosurePage .content {
    font-size: 18px;
  }
  .disclosurePage .downloadBtn {
    padding: 0.8rem 1.2rem;
  }
  .disclosurePage .downloadBtn a {
    font-size: 14px;
  }
  .disclosurePage .downloadBtn a .icon {
    font-size: 24px;
    margin-top: -2%;
  }
}
@media screen and (min-width: 1024px) {
  .disclosurePage {
    padding: 2rem 6rem;
  }
  .disclosurePage .pageTitle {
    font-size: 35px;
  }
  .disclosurePage .content {
    margin-top: 8rem;
    font-size: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .disclosurePage {
    padding: 3rem 10rem;
  }
  .disclosurePage .pageTitle {
    font-size: 40px;
  }
  .disclosurePage .content {
    margin-top: 15rem;
    font-size: 25px;
  }
  .disclosurePage .downloadBtn {
    padding: 0.8rem 1.2rem;
  }
  .disclosurePage .downloadBtn a {
    font-size: 15px;
  }
  .disclosurePage .downloadBtn a .icon {
    font-size: 25px;
    margin-top: -2%;
  }
}/*# sourceMappingURL=vtForm.css.map */
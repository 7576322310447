/*Default colors and styling applied to all the sections*/
:root {
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;
  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);
  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.principlePage {
  width: 100%;
  padding: 2rem 2rem;
}
.principlePage .pageTitle {
  font-size: 20px;
}
.principlePage .messageDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.principlePage .messageDiv .imgDiv {
  width: 70%;
  height: 30vh;
  margin: 0 auto;
  overflow: hidden;
}
.principlePage .messageDiv .imgDiv .img {
  width: 100%;
  height: 100%;
}
.principlePage .messageDiv .message {
  width: 100%;
  text-align: justify;
}
.principlePage .messageDiv .message .text {
  font-size: 15px;
}

@media screen and (min-width: 450px) {
  .principlePage .messageDiv .imgDiv {
    height: 35vh;
  }
}
@media screen and (min-width: 600px) {
  .principlePage {
    padding: 2rem 3rem;
  }
  .principlePage .messageDiv {
    gap: 1.5rem;
  }
  .principlePage .messageDiv .imgDiv {
    height: 35vh;
  }
}
@media screen and (min-width: 840px) {
  .principlePage {
    padding: 2rem 4rem;
  }
  .principlePage .messageDiv .imgDiv {
    width: 30%;
    height: 35vh;
  }
  .principlePage .messageDiv .message .text {
    font-size: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .principlePage {
    padding: 2rem 6rem 4rem;
  }
  .principlePage .pageTitle {
    font-size: 35px;
  }
  .principlePage .messageDiv {
    padding-top: 2rem;
    flex-direction: row;
    gap: 2rem;
  }
  .principlePage .messageDiv .imgDiv {
    width: 30%;
    height: 35vh;
  }
  .principlePage .messageDiv .message .text {
    font-size: 18px;
  }
}
@media screen and (min-width: 1440px) {
  .principlePage {
    padding: 3rem 10rem 4rem;
  }
  .principlePage .pageTitle {
    font-size: 35px;
  }
  .principlePage .messageDiv {
    padding: 2rem;
    flex-direction: row;
    gap: 2rem;
  }
  .principlePage .messageDiv .imgDiv {
    width: 30%;
    height: 35vh;
  }
  .principlePage .messageDiv .message .text {
    font-size: 18px;
  }
}/*# sourceMappingURL=principle.css.map */
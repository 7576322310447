.admissionProcessPage {
  padding: 1rem 2rem;
  margin-bottom: 2rem;
}
.admissionProcessPage .pageTitle {
  font-size: 18px;
}
.admissionProcessPage .titleSection {
  width: 100%;
  text-align: center;
  padding: 1rem;
}
.admissionProcessPage .titleSection .subTitle {
  font-size: 15px;
}
.admissionProcessPage .titleSection .subHeading {
  font-size: 12px;
}
.admissionProcessPage .sectionContentDiv {
  text-align: justify;
}
.admissionProcessPage .sectionContentDiv .sectionContent {
  margin-top: 10px;
  font-size: 12px;
  margin-bottom: 10px;
}
.admissionProcessPage .sectionContentDiv .sectionContent span {
  font-weight: 600;
}
.admissionProcessPage .sectionContentDiv .contentTitle {
  font-size: 15px;
  font-weight: 600;
}
.admissionProcessPage .sectionContentDiv .contentList {
  margin-left: 1.5rem;
  margin-bottom: 10px;
}
.admissionProcessPage .sectionContentDiv .contentList .listItem {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  list-style-type: circle;
}

@media screen and (min-width: 1024px) {
  .admissionProcessPage {
    padding: 3rem 6rem;
  }
  .admissionProcessPage .pageTitle {
    font-size: 30px;
  }
  .admissionProcessPage .titleSection .subTitle {
    font-size: 20px;
  }
  .admissionProcessPage .titleSection .subHeading {
    font-size: 18px;
  }
  .admissionProcessPage .sectionContentDiv .sectionContent {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .admissionProcessPage .sectionContentDiv .contentTitle {
    font-size: 22px;
  }
  .admissionProcessPage .sectionContentDiv .contentList {
    margin-left: 3rem;
    margin-bottom: 30px;
  }
  .admissionProcessPage .sectionContentDiv .contentList .listItem {
    font-size: 18px;
  }
}/*# sourceMappingURL=admissionProcess.css.map */
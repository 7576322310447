/*Default colors and styling applied to all the sections*/
:root {
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;
  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);
  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.iconBarSection {
  width: 100%;
  background: var(--maroonColor);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem 0.9rem;
}
.iconBarSection .iconsDiv {
  padding-top: 0.2rem;
}
.iconBarSection .iconsDiv a {
  height: -moz-max-content;
  height: max-content;
  text-decoration: none;
}
.iconBarSection .iconsDiv a .icon {
  color: white;
  font-size: 20px;
  margin-right: 12px;
}/*# sourceMappingURL=social.css.map */
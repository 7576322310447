/*Default colors and styling applied to all the sections*/
:root {
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;
  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);
  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.mainFooterDiv {
  width: 100%;
}
.mainFooterDiv .imgDiv {
  width: 100%;
  height: 40vh;
  overflow: hidden;
}
.mainFooterDiv .imgDiv .footerImg {
  width: 100%;
  height: 100%;
}
.mainFooterDiv .footerContentDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  background: rgb(30, 30, 30);
  gap: 2rem;
}
.mainFooterDiv .footerContentDiv .leftSection {
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 2px dotted white;
}
.mainFooterDiv .footerContentDiv .leftSection .leftContentDiv {
  width: 100%;
  color: white;
  text-align: center;
}
.mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .title {
  font-size: 15px;
  font-weight: 400;
}
.mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .address {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2rem;
}
.mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .contact {
  font-size: 10px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}
.mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .contact .icon {
  font-size: 12px;
  margin-right: 5px;
  margin-top: -1%;
}
.mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .socialIcons {
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 1rem 4rem;
}
.mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .socialIcons .iconDiv {
  margin: 0 auto;
  border: 1px solid white;
  border-radius: 16px;
  padding: 0.2rem 0.3rem;
}
.mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .socialIcons .iconDiv .icon {
  font-size: 20px;
}
.mainFooterDiv .footerContentDiv .rightSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.mainFooterDiv .footerContentDiv .rightSection .topSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.mainFooterDiv .footerContentDiv .rightSection .topSection .block {
  width: 50%;
}
.mainFooterDiv .footerContentDiv .rightSection .topSection .block .blockTitle {
  font-size: 15px;
  color: var(--redColor);
  letter-spacing: 1px;
}
.mainFooterDiv .footerContentDiv .rightSection .topSection .block .linkTextDiv {
  width: 100%;
}
.mainFooterDiv .footerContentDiv .rightSection .topSection .block .linkTextDiv .linkText {
  color: white;
  font-size: 10px;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
}
.mainFooterDiv .footerContentDiv .rightSection .topSection .block .linkTextDiv .linkText .link {
  text-decoration: none;
}
.mainFooterDiv .footerContentDiv .rightSection .topSection .block .linkTextDiv .linkText .color {
  color: white;
}
.mainFooterDiv .footerContentDiv .rightSection .mapSection {
  width: 100%;
}
.mainFooterDiv .footerContentDiv .rightSection .mapSection .blockTitle {
  font-size: 15px;
  color: var(--redColor);
  letter-spacing: 1px;
}
.mainFooterDiv .footerContentDiv .rightSection .mapSection .map {
  width: 100%;
}

@media screen and (min-width: 600px) {
  .mainFooterDiv .footerContentDiv {
    padding: 2rem 3rem;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .title {
    font-size: 18px;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .address {
    font-size: 15px;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .contact {
    font-size: 12px;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .contact .icon {
    font-size: 13px;
    margin-top: 0%;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .socialIcons {
    padding: 2rem 8rem;
  }
  .mainFooterDiv .footerContentDiv .rightSection .topSection .block .blockTitle {
    font-size: 18px;
  }
  .mainFooterDiv .footerContentDiv .rightSection .topSection .block .linkTextDiv .linkText {
    font-size: 15px;
  }
  .mainFooterDiv .footerContentDiv .rightSection .mapSection .blockTitle {
    font-size: 18px;
  }
  .mainFooterDiv .footerContentDiv .rightSection .mapSection .map {
    height: 40vh;
  }
}
@media screen and (min-width: 840px) {
  .mainFooterDiv .imgDiv {
    height: 55vh;
  }
  .mainFooterDiv .footerContentDiv {
    padding: 3rem 6rem;
  }
}
@media screen and (min-width: 1024px) {
  .mainFooterDiv .footerContentDiv {
    padding: 3rem 6rem;
    flex-direction: row;
  }
  .mainFooterDiv .footerContentDiv .leftSection {
    width: 30%;
    border-bottom: none;
    border-right: 1px dotted white;
    padding-right: 1rem;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .title {
    font-size: 15px;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .address {
    font-size: 12px;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .contact {
    font-size: 12px;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .contact .icon {
    font-size: 13px;
    margin-top: 0%;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .socialIcons {
    padding: 2rem 1.5rem;
  }
  .mainFooterDiv .footerContentDiv .rightSection {
    width: 70%;
    flex-direction: row;
    gap: 1rem;
  }
  .mainFooterDiv .footerContentDiv .rightSection .topSection .block .blockTitle {
    font-size: 15px;
  }
  .mainFooterDiv .footerContentDiv .rightSection .topSection .block .linkTextDiv .linkText {
    font-size: 12px;
  }
  .mainFooterDiv .footerContentDiv .rightSection .mapSection .blockTitle {
    font-size: 15px;
  }
  .mainFooterDiv .footerContentDiv .rightSection .mapSection .map {
    height: 30vh;
  }
}
@media screen and (min-width: 1440px) {
  .mainFooterDiv .imgDiv {
    height: 65vh;
  }
  .mainFooterDiv .footerContentDiv {
    padding: 4rem 8rem;
  }
  .mainFooterDiv .footerContentDiv .leftSection {
    width: 30%;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .title {
    font-size: 18px;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .address {
    font-size: 15px;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .contact {
    font-size: 13px;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .contact .icon {
    font-size: 14px;
    margin-top: -0.5%;
  }
  .mainFooterDiv .footerContentDiv .leftSection .leftContentDiv .socialIcons {
    padding: 2rem 4rem;
  }
  .mainFooterDiv .footerContentDiv .rightSection {
    width: 70%;
    flex-direction: row;
    gap: 0;
  }
  .mainFooterDiv .footerContentDiv .rightSection .topSection .block .blockTitle {
    font-size: 18px;
  }
  .mainFooterDiv .footerContentDiv .rightSection .topSection .block .linkTextDiv .linkText {
    font-size: 15px;
  }
  .mainFooterDiv .footerContentDiv .rightSection .mapSection .blockTitle {
    font-size: 18px;
  }
  .mainFooterDiv .footerContentDiv .rightSection .mapSection .map {
    height: 30vh;
  }
}/*# sourceMappingURL=mainFooter.css.map */
/*Default colors and styling applied to all the sections*/
:root{
    --redColor: #DC0000;
    --maroonColor: #850000;
    --creamColor: #FFDB89;
    --lightCreamColor: #FFF6C3;
  
  
    --darkColor: #0A2647;
    --hoverLightColor: #144272;
    --primaryColor: hsl(199,100%,33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, #2C74B3, #144272);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 11%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(163, 163, 163);
    --inputColor: rgb(239, 239, 239);
    --cardBG: rgb(225, 225, 235);
  
    --blueColor: #22577a;
    --tealColor: #38a3a5;
    --greenColor: #57cc99;
    --lightGreenColor: #80ed99;
    --lightColor: #c7f9cc;
}

.mainFooterDiv{
    width: 100%;

    .imgDiv{
        width: 100%;
        height: 40vh;
        overflow: hidden;
        .footerImg{
            width: 100%;
            height: 100%;
        }
    }

    .footerContentDiv{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 2rem 2rem;
        background: rgb(30, 30, 30);
        gap: 2rem;

        .leftSection{
            width: 100%;
            padding-bottom: 1rem;
            border-bottom: 2px dotted white;

            // .logoDiv{
            //     width: 30%;
            //     height: 19vh;
            //     margin: 0 auto;
            //     overflow: hidden;
            //     .logoImg{
            //         width: 100%;
            //         height: 100%;
            //     }
            // }

            .leftContentDiv{
                width: 100%;
                color: white;
                text-align: center;
                .title{
                    font-size: 15px;
                    font-weight: 400;
                }
                .address{
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 2rem;
                }

                .contact{
                    font-size: 10px;
                    letter-spacing: .5px;
                    margin-bottom: 5px;
                    .icon{
                        font-size: 12px;
                        margin-right: 5px;
                        margin-top: -1%;
                    }
                }
                .socialIcons{
                    width: 100%;
                    display: flex;
                    gap: 1rem;
                    padding: 1rem 4rem;

                    .iconDiv{
                        margin: 0 auto;
                        border: 1px solid white;
                        border-radius: 16px;
                        padding: .2rem .3rem;

                        .icon{
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .rightSection{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .topSection{
                width: 100%;
                display: flex;
                justify-content: space-between;
                .block{
                    width: 50%;
                    .blockTitle{
                        font-size: 15px;
                        color: var(--redColor);
                        letter-spacing: 1px;
                    }

                    .linkTextDiv{
                        width: 100%;
                        .linkText{
                            color: white;
                            font-size: 10px;
                            margin-bottom: 5px;
                            letter-spacing: .5px;
                            .link{
                                text-decoration: none;
                            }
                            .color{
                                color: white;
                            }
                        }
                    }
                }
            }

            .mapSection{
                width: 100%;
                .blockTitle{
                    font-size: 15px;
                    color: var(--redColor);
                    letter-spacing: 1px;
                }
                .map{
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .mainFooterDiv{
        .footerContentDiv{
            padding: 2rem 3rem;

            .leftSection{
                .leftContentDiv{
                    .title{
                        font-size: 18px;
                    }
                    .address{
                        font-size: 15px;
                    }

                    .contact{
                        font-size: 12px;
                        .icon{
                            font-size: 13px;
                            margin-top: 0%;
                        }
                    }
                    .socialIcons{
                        padding: 2rem 8rem;
                    }
                }
            }

            .rightSection{
                .topSection{
                    .block{
                        .blockTitle{
                            font-size: 18px;
                        }
                        .linkTextDiv{
                            .linkText{
                                font-size: 15px;
                            }
                        }
                    }
                }
                .mapSection{
                    .blockTitle{
                        font-size: 18px;
                    }
                    .map{
                        height: 40vh;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 840px) {
    .mainFooterDiv{
        .imgDiv{
            height: 55vh;
        }
        .footerContentDiv{
            padding: 3rem 6rem;
        }
    }
}

@media screen and (min-width: 1024px) {
    .mainFooterDiv{
        .footerContentDiv{
            padding: 3rem 6rem;
            flex-direction: row;

            .leftSection{
                width: 30%;
                border-bottom: none;
                border-right: 1px dotted white;
                padding-right: 1rem;

                .leftContentDiv{
                    .title{
                        font-size: 15px;
                    }
                    .address{
                        font-size: 12px;
                    }

                    .contact{
                        font-size: 12px;
                        .icon{
                            font-size: 13px;
                            margin-top: 0%;
                        }
                    }
                    .socialIcons{
                        padding: 2rem 1.5rem;
                    }
                }
            }

            .rightSection{
                width: 70%;
                flex-direction: row;
                gap: 1rem;
                .topSection{
                    .block{
                        .blockTitle{
                            font-size: 15px;
                        }
                        .linkTextDiv{
                            .linkText{
                                font-size: 12px;
                            }
                        }
                    }
                }
                .mapSection{
                    .blockTitle{
                        font-size: 15px;
                    }
                    .map{
                        height: 30vh;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .mainFooterDiv{
        .imgDiv{
            height: 65vh;
        }
        .footerContentDiv{
            padding: 4rem 8rem;

            .leftSection{
                width: 30%;

                .leftContentDiv{
                    .title{
                        font-size: 18px;
                    }
                    .address{
                        font-size: 15px;
                    }

                    .contact{
                        font-size: 13px;
                        .icon{
                            font-size: 14px;
                            margin-top: -.5%;
                        }
                    }
                    .socialIcons{
                        padding: 2rem 4rem;
                    }
                }
            }

            .rightSection{
                width: 70%;
                flex-direction: row;
                gap: 0;
                .topSection{
                    .block{
                        .blockTitle{
                            font-size: 18px;
                        }
                        .linkTextDiv{
                            .linkText{
                                font-size: 15px;
                            }
                        }
                    }
                }
                .mapSection{
                    .blockTitle{
                        font-size: 18px;
                    }
                    .map{
                        height: 30vh;
                    }
                }
            }
        }
    }
}
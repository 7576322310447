/*Default colors and styling applied to all the sections*/
:root {
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;
  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);
  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.topbarBox {
  z-index: 1000000;
  top: 0;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 2rem;
  position: sticky;
  background-color: var(--whiteColor);
  transition: background-color 0.4s ease-in-out;
}
.topbarBox .left-section .searchBox .icon {
  font-size: 30px;
  color: var(--maroonColor);
}
@media screen and (max-width: 1040px) {
  .topbarBox .right-section .navbarDiv {
    z-index: 1000;
    height: 100vh;
    flex-direction: column;
    background: var(--lightCreamColor);
    padding: 2rem 4rem 5rem;
    gap: 2rem;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
  }
  .topbarBox .right-section .navbarDiv .navList {
    margin-top: 3rem;
    flex-direction: column;
    color: var(--maroonColor);
    font-size: 15px;
    gap: 1.1rem;
    letter-spacing: 2px;
    font-weight: 700;
    margin-bottom: 0;
    padding-left: 0;
  }
  .topbarBox .right-section .navbarDiv .navList .navItem .menuIcon {
    font-size: 25px;
  }
  .topbarBox .right-section .navbarDiv .navList .navItem:hover .menuIcon {
    transform: rotate(-90deg);
    transition: ease-out 0.5s;
  }
  .topbarBox .right-section .navbarDiv .loginBtn {
    padding: 0.8rem 2.7rem;
    background: var(--maroonColor);
  }
  .topbarBox .right-section .navbarDiv .loginBtn .loginText {
    color: var(--whiteColor);
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 2.5px;
    margin-bottom: 0;
  }
  .topbarBox .right-section .navbarDiv.hidden {
    display: none;
  }
  .topbarBox .right-section .navbarDiv.extend {
    transform: translate();
    transition: ease-out 0.5s;
    right: 100px;
  }
  .topbarBox .right-section .navbarDiv.back {
    transform: translate();
    transition: ease-out 0.5s;
    right: 0;
  }
  .topbarBox .right-section .hamburger-icon {
    z-index: 10000;
    /* ----- animations--------- */
    /* clicked */
    /* unclicked */
  }
  .topbarBox .right-section .hamburger-icon .stick {
    width: 30px;
    margin-bottom: 5px;
    height: 4.5px;
    background: var(--maroonColor);
  }
  .topbarBox .right-section .hamburger-icon .stick:nth-child(1) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .topbarBox .right-section .hamburger-icon .stick:nth-child(3) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .topbarBox .right-section .hamburger-icon .stick.clicked:nth-child(1) {
    transform: rotate(45deg) translate(0.65em, 1.25em);
    transition: ease-out 0.5s;
    background: var(--maroonColor);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .topbarBox .right-section .hamburger-icon .stick.clicked:nth-child(2) {
    transform: scale(0);
    transition: ease-out 0.5s;
    background: var(--maroonColor);
  }
  .topbarBox .right-section .hamburger-icon .stick.clicked:nth-child(3) {
    transform: rotate(135deg) translate(-0.4em, 1em);
    transition: ease-out 0.5s;
    background: var(--maroonColor);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .topbarBox .right-section .hamburger-icon .stick.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
  }
}

.topbarBox.scrolled {
  background-color: var(--maroonColor);
  opacity: 0.98;
}
.topbarBox.scrolled .left-section .searchBox .icon {
  color: var(--whiteColor);
}
.topbarBox.scrolled .right-section .hamburger-icon .stick {
  background: var(--whiteColor);
}

@media screen and (min-width: 600px) {
  .topbarBox {
    padding: 1rem 3rem;
  }
}
@media screen and (min-width: 840px) {
  .topbarBox {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media screen and (min-width: 1041px) {
  .topbarBox {
    padding: 1.5rem 6rem;
    top: -2px;
  }
  .topbarBox .right-section {
    gap: 2rem;
  }
  .topbarBox .right-section .searchBox {
    display: none;
  }
  .topbarBox .right-section .navbarDiv {
    flex-direction: row;
    gap: 1.2rem;
  }
  .topbarBox .right-section .navbarDiv .navList {
    flex-direction: row;
    gap: 1.5rem;
    color: var(--maroonColor);
    margin-bottom: 0;
  }
  .topbarBox .right-section .navbarDiv .navList .navItem {
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.1px;
  }
  .topbarBox .right-section .navbarDiv .loginBtn {
    padding: 0.5rem 1rem;
    background: var(--maroonColor);
  }
  .topbarBox .right-section .navbarDiv .loginBtn .loginText {
    font-weight: 600;
    color: var(--whiteColor);
    font-size: 1rem;
    letter-spacing: 1.5px;
    margin-bottom: 0;
  }
  .topbarBox .right-section .hamburger-icon {
    display: none;
  }
  .topbarBox.scrolled .right-section .navbarDiv .navList {
    color: var(--whiteColor);
  }
  .topbarBox.scrolled .right-section .navbarDiv .loginBtn {
    background: var(--whiteColor);
  }
  .topbarBox.scrolled .right-section .navbarDiv .loginBtn .loginText {
    color: var(--maroonColor);
  }
}
@media screen and (min-width: 1440px) {
  .topbarBox {
    padding: 1.5rem 10rem;
  }
  .topbarBox .right-section {
    gap: 2rem;
  }
  .topbarBox .right-section .searchBox {
    display: none;
  }
  .topbarBox .right-section .navbarDiv {
    flex-direction: row;
    gap: 1.5rem;
  }
  .topbarBox .right-section .navbarDiv .navList {
    flex-direction: row;
    gap: 1.8rem;
    margin-bottom: 0;
  }
  .topbarBox .right-section .navbarDiv .navList .navItem {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.1px;
  }
  .topbarBox .right-section .navbarDiv .loginBtn {
    padding: 0.6rem 1.2rem;
  }
  .topbarBox .right-section .navbarDiv .loginBtn .loginText {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1.5px;
    margin-bottom: 0;
  }
  .topbarBox .right-section .hamburger-icon {
    display: none;
  }
}
@media screen and (max-width: 1040px) {
  .topbarBox .right-section .subNavbarDiv {
    z-index: 1000;
    width: 150px;
    height: 100vh;
    flex-direction: column;
    background: var(--maroonColor);
    padding: 5rem 2rem;
    gap: 2rem;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
  }
  .topbarBox .right-section .subNavbarDiv .subHamburger {
    z-index: 5000;
    position: absolute;
    right: 0;
    /* ----- animations--------- */
    /* clicked */
  }
  .topbarBox .right-section .subNavbarDiv .subHamburger .stick {
    width: 20px;
    margin-bottom: 5px;
    height: 3px;
    background: var(--creamColor);
  }
  .topbarBox .right-section .subNavbarDiv .subHamburger .stick:nth-child(1) {
    transform: rotate(45deg) translate(-0.25em, 0.75em);
    transition: ease-out 0.5s;
  }
  .topbarBox .right-section .subNavbarDiv .subHamburger .stick:nth-child(2) {
    transform: scale(0);
    transition: ease-out 0.5s;
    background: none;
  }
  .topbarBox .right-section .subNavbarDiv .subHamburger .stick:nth-child(3) {
    transform: rotate(135deg) translate(0, 1em);
    transition: ease-out 0.5s;
  }
  .topbarBox .right-section .subNavbarDiv .navList {
    margin-top: 3rem;
    flex-direction: column;
    color: var(--lightCreamColor);
    font-size: 12px;
    gap: 1.1rem;
    letter-spacing: 2px;
    font-weight: 500;
    margin-bottom: 0;
    padding-left: 0;
    text-transform: uppercase;
  }
  .topbarBox .right-section .subNavbarDiv.hidden {
    display: none;
  }
}

@media screen and (min-width: 1041px) {
  .topbarBox .right-section .subNavbarDiv {
    display: none;
  }
}
@media screen and (max-width: 1040px) {
  .topbarBox .right-section .navbarDiv .navList .navItem .dropDownListWeb {
    display: none;
  }
}
.topbarBox .right-section .navbarDiv .navList .navItem .dropDownListWeb {
  position: absolute;
  top: 0;
  margin-top: 80px;
  background: var(--lightCreamColor);
  padding: 1.5rem 0;
}
.topbarBox .right-section .navbarDiv .navList .navItem .dropDownListWeb .listItemDiv {
  padding: 0.375rem 1.5rem;
}
.topbarBox .right-section .navbarDiv .navList .navItem .dropDownListWeb .listItemDiv .navItem {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  transform: translateY();
}
.topbarBox .right-section .navbarDiv .navList .navItem .dropDownListWeb .listItemDiv .navItem .icon {
  color: var(--maroonColor);
  font-size: 20px;
}
.topbarBox .right-section .navbarDiv .navList .navItem .dropDownListWeb .listItemDiv .navItem .link {
  text-decoration: none;
}
.topbarBox .right-section .navbarDiv .navList .navItem .dropDownListWeb .listItemDiv .navItem .color {
  color: var(--maroonColor);
}
.topbarBox .right-section .navbarDiv .navList .navItem .dropDownListWeb .listItemDiv:hover {
  transform: translateX(7px);
}
.topbarBox .right-section .navbarDiv .navList .navItem .dropDownListWeb.hidden {
  display: none;
}/*# sourceMappingURL=navbar.css.map */
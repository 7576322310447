/*Default colors and styling applied to all the sections*/
:root {
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;
  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);
  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.grievanceSectionPage {
  width: 100%;
  padding: 1rem 2rem 3rem;
}
.grievanceSectionPage .pageTitle {
  font-size: 20px;
  font-weight: 600;
}
.grievanceSectionPage .contentSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.grievanceSectionPage .contentSection .leftSection {
  width: 100%;
}
.grievanceSectionPage .contentSection .leftSection .sectionSubTitle {
  font-size: 15px;
}
.grievanceSectionPage .contentSection .leftSection .contentTable {
  width: 100%;
}
.grievanceSectionPage .contentSection .leftSection .contentTable .tableDivSection {
  width: 100%;
  display: flex;
}
.grievanceSectionPage .contentSection .leftSection .contentTable .tableDivSection .section {
  width: 50%;
  font-size: 12px;
  padding: 0.5rem 0;
}
.grievanceSectionPage .contentSection .leftSection .contentTable .tableDivSection .section .sectionContent {
  width: 100%;
  margin-bottom: 0;
}
.grievanceSectionPage .contentSection .leftSection .contentTable .tableDivSection .section .sectionContent span {
  font-weight: 600;
}
.grievanceSectionPage .contentSection .rightSection {
  width: 100%;
  text-align: center;
}
.grievanceSectionPage .contentSection .rightSection .sectionSubTitle {
  font-size: 15px;
}
.grievanceSectionPage .contentSection .rightSection .contactForm {
  width: 100%;
  padding: 2rem;
  text-align: left;
  background: var(--lightCreamColor);
}
.grievanceSectionPage .contentSection .rightSection .contactForm .label {
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 500;
}
.grievanceSectionPage .contentSection .rightSection .contactForm input, .grievanceSectionPage .contentSection .rightSection .contactForm textarea {
  margin-bottom: 0.8rem;
  width: 100%;
  padding: 0.3rem;
  font-size: 10px;
  border: 1px solid var(--redColor);
  background: var(--lightCreamColor);
}
.grievanceSectionPage .contentSection .rightSection .contactForm .formBtn {
  border: none;
  font-size: 10px;
  padding: 0.5rem;
  background: var(--maroonColor);
  color: var(--lightCreamColor);
}

@media screen and (min-width: 600px) {
  .grievanceSectionPage {
    padding: 2rem 3rem 4rem;
  }
  .grievanceSectionPage .pageTitle {
    font-size: 25px;
    margin-bottom: 2rem;
  }
  .grievanceSectionPage .contentSection {
    gap: 3rem;
  }
  .grievanceSectionPage .contentSection .leftSection .sectionSubTitle {
    font-size: 18px;
    margin-bottom: 1rem;
  }
  .grievanceSectionPage .contentSection .leftSection .contentTable .tableDivSection .section {
    font-size: 15px;
  }
  .grievanceSectionPage .contentSection .rightSection .sectionSubTitle {
    font-size: 18px;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm {
    padding: 2rem 3rem;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm .label {
    font-size: 15px;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm input, .grievanceSectionPage .contentSection .rightSection .contactForm textarea {
    padding: 0.4rem;
    font-size: 12px;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm .formBtn {
    font-size: 12px;
    padding: 0.5rem 0.8rem;
  }
}
@media screen and (min-width: 840px) {
  .grievanceSectionPage {
    padding: 2rem 4rem 4rem;
  }
  .grievanceSectionPage .pageTitle {
    font-size: 30px;
    margin-bottom: 0.5rem;
  }
  .grievanceSectionPage hr {
    margin-bottom: 2rem;
  }
  .grievanceSectionPage .contentSection {
    gap: 3rem;
  }
  .grievanceSectionPage .contentSection .leftSection .sectionSubTitle {
    font-size: 20px;
    margin-bottom: 1.5rem;
  }
  .grievanceSectionPage .contentSection .leftSection .contentTable .tableDivSection .section {
    font-size: 16px;
  }
  .grievanceSectionPage .contentSection .rightSection .sectionSubTitle {
    font-size: 20px;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm {
    padding: 2rem 4rem;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm .label {
    font-size: 16px;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm input, .grievanceSectionPage .contentSection .rightSection .contactForm textarea {
    padding: 0.5rem;
    font-size: 13px;
    margin-bottom: 1.5rem;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm .formBtn {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .grievanceSectionPage {
    padding: 2rem 6rem 4rem;
  }
  .grievanceSectionPage .pageTitle {
    font-size: 30px;
    margin-bottom: 0.5rem;
  }
  .grievanceSectionPage hr {
    margin-bottom: 3rem;
  }
  .grievanceSectionPage .contentSection {
    gap: 3rem;
    flex-direction: row;
  }
  .grievanceSectionPage .contentSection .leftSection .sectionSubTitle {
    font-size: 18px;
    margin-bottom: 2rem;
  }
  .grievanceSectionPage .contentSection .leftSection .contentTable .tableDivSection .section {
    font-size: 15px;
  }
  .grievanceSectionPage .contentSection .rightSection .sectionSubTitle {
    font-size: 18px;
  }
  .grievanceSectionPage .contentSection .rightSection hr {
    margin-bottom: 0;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm {
    padding: 2rem 4rem;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm .label {
    font-size: 16px;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm input, .grievanceSectionPage .contentSection .rightSection .contactForm textarea {
    padding: 0.5rem;
    font-size: 13px;
    margin-bottom: 1.5rem;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm .formBtn {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
}
@media screen and (min-width: 1440px) {
  .grievanceSectionPage {
    padding: 2rem 10rem 4rem;
  }
  .grievanceSectionPage .pageTitle {
    font-size: 30px;
    margin-bottom: 0.5rem;
  }
  .grievanceSectionPage hr {
    margin-bottom: 3rem;
  }
  .grievanceSectionPage .contentSection {
    gap: 3rem;
    flex-direction: row;
  }
  .grievanceSectionPage .contentSection .leftSection .sectionSubTitle {
    font-size: 20px;
    margin-bottom: 2rem;
  }
  .grievanceSectionPage .contentSection .leftSection .contentTable .tableDivSection .section {
    font-size: 16px;
  }
  .grievanceSectionPage .contentSection .rightSection .sectionSubTitle {
    font-size: 20px;
  }
  .grievanceSectionPage .contentSection .rightSection hr {
    margin-bottom: 0;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm {
    padding: 2rem 4rem;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm .label {
    font-size: 16px;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm input, .grievanceSectionPage .contentSection .rightSection .contactForm textarea {
    padding: 0.5rem;
    font-size: 13px;
    margin-bottom: 1.5rem;
  }
  .grievanceSectionPage .contentSection .rightSection .contactForm .formBtn {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
}/*# sourceMappingURL=grievance.css.map */
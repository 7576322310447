
/*Default colors and styling applied to all the sections*/
:root{
    --redColor: #DC0000;
    --maroonColor: #850000;
    --creamColor: #FFDB89;
    --lightCreamColor: #FFF6C3;
  
  
    --darkColor: #0A2647;
    --hoverLightColor: #144272;
    --primaryColor: hsl(199,100%,33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, #2C74B3, #144272);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 11%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(163, 163, 163);
    --inputColor: rgb(239, 239, 239);
    --cardBG: rgb(225, 225, 235);
  
    --blueColor: #22577a;
    --tealColor: #38a3a5;
    --greenColor: #57cc99;
    --lightGreenColor: #80ed99;
    --lightColor: #c7f9cc;
}

.disclosurePage{
    width: 100%;
    padding: 2rem;
    height: 75vh;

    .pageTitle{
        font-size: 20px;
        font-weight: 600;
        color: var(--maroonColor);
    }

    .content{
        margin-top: 5rem;
        font-size: 15px;
        .icon{
            font-size: 20px;
            color: var(--maroonColor);
        }
    }

    .downloadBtn{
        padding: .5rem 1rem;
        background: var(--maroonColor);
        border: none;
        border-radius: 5px;
        a{
            text-decoration: none;
            font-size: 12px;
            color: white;
            .icon{
                font-size: 20px;
                margin-right: 5px;
                margin-top: -5%;
            }
        }
    }
}

@media screen and (min-width: 600px){
    .disclosurePage{
        padding: 2rem 3rem;

        .pageTitle{
            font-size: 25px;
        }

        .content{
            font-size: 16px;
        }

        .downloadBtn{
            a{
                font-size: 13px;
                .icon{
                    font-size: 22px;
                    margin-top: -2%;
                }
            }
        }
    }
}

@media screen and (min-width: 840px){
    .disclosurePage{
        padding: 2rem 4rem;

        .pageTitle{
            font-size: 30px;
        }

        .content{
            font-size: 18px;
        }

        .downloadBtn{
        padding: .8rem 1.2rem;
            a{
                font-size: 14px;
                .icon{
                    font-size: 24px;
                    margin-top: -2%;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px){
    .disclosurePage{
        padding: 2rem 6rem;

        .pageTitle{
            font-size: 35px;
        }

        .content{
            margin-top: 8rem;
            font-size: 20px;
        }
    }
}

@media screen and (min-width: 1024px){
    .disclosurePage{
        padding: 3rem 10rem;

        .pageTitle{
            font-size: 40px;
        }

        .content{
            margin-top: 15rem;
            font-size: 25px;
        }

        .downloadBtn{
        padding: .8rem 1.2rem;
            a{
                font-size: 15px;
                .icon{
                    font-size: 25px;
                    margin-top: -2%;
                }
            }
        }
    }
}
/*Default colors and styling applied to all the sections*/
:root{
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;


  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);

  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.countSection{
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 80vh;

    .bgImg{
        width: 100%;
        height: 100%;
    }
    .overlay{
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.604);
      top: 0;
      bottom: 0;
      z-index: 100;
      mix-blend-mode: hard-light;
    }

    .contentDiv{
        width: 100%;
        padding: 4rem 2rem;
        position: absolute;
        top: 0;
        z-index: 1000;
        display: flex;
        flex-direction: column;

        .count{
            text-align: center;
            margin-bottom: 1.5rem;

            .countTitle{
                font-size: 45px;
                color: white;
                font-weight: 600;
                margin-bottom: 1rem;
            }
            .lineDiv{
                margin: 0 auto;
                background: var(--redColor);
                height: 2px;
                width: 40px;
                position: relative;
                margin-bottom: 1rem;

                .boxPart{
                    background: var(--redColor);
                    width: 20px;
                    height: 8px;
                    border-radius: 16px;
                    position: absolute;
                    top: 0;
                    margin-top: -8%;
                    margin-left: 25%;
                }
            }

            .subTitle{
                font-size: 18px;
                font-weight: 600;
                color: white;
            }
        }
    }
}

@media screen and (min-width: 840px){
    .countSection{
        height: 35vh;
        .bgImg{
            object-fit: cover;
        }
        .contentDiv{
            padding: 4rem 8rem;
            flex-direction: row;
            justify-content: space-between;

            .count{
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (min-width: 1024px){
    .countSection{
        height: 35vh;
        .bgImg{
            object-fit: cover;
        }
        .contentDiv{
            padding: 4rem 12rem;

            .count{
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (min-width: 1440px){
    .countSection{
        height: 38vh;
        .bgImg{
            object-fit: cover;
        }
        .contentDiv{
            padding: 5rem 15rem;

            .count{
                margin-bottom: 0;
                .countTitle{
                    font-size: 55px;
                }
            }
        }
    }
}

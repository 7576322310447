/*Default colors and styling applied to all the sections*/
:root{
    --redColor: #DC0000;
    --maroonColor: #850000;
    --creamColor: #FFDB89;
    --lightCreamColor: #FFF6C3;
  
  
    --darkColor: #0A2647;
    --hoverLightColor: #144272;
    --primaryColor: hsl(199,100%,33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, #2C74B3, #144272);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 11%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(163, 163, 163);
    --inputColor: rgb(239, 239, 239);
    --cardBG: rgb(225, 225, 235);
  
    --blueColor: #22577a;
    --tealColor: #38a3a5;
    --greenColor: #57cc99;
    --lightGreenColor: #80ed99;
    --lightColor: #c7f9cc;
}

.contactDetailsPage{
    width: 100%;
    padding: 1rem 2rem 3rem;

    .pageTitle{
        font-size: 20px;
        margin-bottom: 1.5rem;
        font-weight: 600;
    }

    .detailsDiv{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .leftSection{
            width: 100%;

            .subHeading{
                font-size: 15px;
            }

            .subHeadingList{
                margin-top: 1rem;
                font-size: 15px;
            }

            .content{
                display: flex;
                gap: .5rem;
                width: 100%;

                .header{
                    font-size: 12px;
                    font-weight: 600;
                    width: 50%;
                }
                .part{
                    width: 50%;
                    font-size: 12px;

                    .partText{
                        font-size: 12px;
                    }
                }
            }
        }

        .rightSection{
            width: 100%;
            .subHeading{
                font-size: 15px;
            }

            .mapArea{
                width: 100%;
                height: 30vh;
                overflow: hidden;
                border: 1px solid var(--greyText);

                .map{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 600px){
    .contactDetailsPage{
        padding: 1rem 3rem 4rem;

        .pageTitle{
            font-size: 25px;
        }

        .detailsDiv{
            gap: 2.5rem;
            .leftSection{
                .subHeading{
                    font-size: 18px;
                }

                .subHeadingList{
                    font-size: 18px;
                }

                .content{
                    .header{
                        font-size: 13px;
                    }
                    .part{
                        font-size: 13px;
                        .partText{
                            font-size: 13px;
                        }
                    }
                }
            }

            .rightSection{
                .subHeading{
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (min-width: 840px){
    .contactDetailsPage{
        padding: 1rem 4rem 4rem;

        .pageTitle{
            font-size: 30px;
            margin-bottom: 2rem;
        }

        .detailsDiv{
            gap: 3rem;
            .leftSection{
                .subHeading{
                    font-size: 20px;
                }

                .subHeadingList{
                    font-size: 20px;
                }

                .content{
                    .header{
                        font-size: 15px;
                    }
                    .part{
                        font-size: 15px;
                        .partText{
                            font-size: 15px;
                        }
                    }
                }
            }

            .rightSection{
                .subHeading{
                    font-size: 20px;
                    margin-bottom: 2rem;
                }
                .mapArea{
                    height: 50vh;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px){
    .contactDetailsPage{
        padding: 1rem 6rem 4rem;

        .pageTitle{
            font-size: 30px;
            margin-bottom: 2rem;
        }

        .detailsDiv{
            gap: 3rem;
            .leftSection{
                .subHeading{
                    font-size: 20px;
                }

                .subHeadingList{
                    font-size: 20px;
                }

                .content{
                    .header{
                        font-size: 15px;
                    }
                    .part{
                        font-size: 15px;
                        .partText{
                            font-size: 15px;
                        }
                    }
                }
            }

            .rightSection{
                .subHeading{
                    font-size: 20px;
                    margin-bottom: 2rem;
                }
                .mapArea{
                    height: 50vh;
                }
            }
        }
    }
}

@media screen and (min-width: 1440px){
    .contactDetailsPage{
        padding: 2rem 10rem 5rem;

        .pageTitle{
            font-size: 45px;
            margin-bottom: 3rem;
        }

        .detailsDiv{
            gap: 4rem;
            .leftSection{
                .subHeading{
                    font-size: 25px;
                }

                .subHeadingList{
                    font-size: 25px;
                    margin-top: 3rem;
                }

                .content{
                    .header{
                        font-size: 18px;
                    }
                    .part{
                        font-size: 18px;
                        .partText{
                            font-size: 18px;
                        }
                    }
                }
            }

            .rightSection{
                .subHeading{
                    font-size: 25px;
                    margin-bottom: 2.5rem;
                }
                .mapArea{
                    height: 50vh;
                }
            }
        }
    }
}
/*Default colors and styling applied to all the sections*/
:root {
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;
  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);
  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.carousel {
  width: 100%;
  height: 70vh;
}
.carousel .carousel-inner {
  height: 100%;
  width: 100%;
}
.carousel .carousel-inner .carousel-item {
  height: 100%;
  width: 100%;
}
.carousel .carousel-inner .w-100 {
  height: 100%;
  width: 100%;
}
.carousel .carousel-inner .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(97, 98, 98);
  top: 0;
  bottom: 0;
  z-index: 1;
  mix-blend-mode: hard-light;
}
.carousel .carousel-inner .carousel-caption {
  z-index: 1000;
  bottom: 150px;
}
.carousel .carousel-inner .carousel-caption h5 {
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.carousel .carousel-inner .carousel-caption p {
  width: 90%;
  margin: auto;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
}
.carousel .carousel-inner .carousel-caption a {
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  background: darkorange;
  padding: 0.5rem 1rem;
  display: inline-block;
  color: #000;
  margin-top: 15px;
}

@media screen and (min-width: 600px) {
  .carousel .carousel-inner .carousel-caption {
    bottom: 130px;
  }
  .carousel .carousel-inner .carousel-caption h5 {
    font-size: 35px;
  }
  .carousel .carousel-inner .carousel-caption p {
    width: 90%;
    font-size: 15px;
  }
  .carousel .carousel-inner .carousel-caption a {
    font-size: 15px;
    background: darkorange;
    padding: 0.7rem 1.4rem;
    margin-top: 20px;
    font-weight: 500;
  }
}
@media screen and (min-width: 840px) {
  .carousel .carousel-inner .carousel-caption {
    bottom: 100px;
  }
  .carousel .carousel-inner .carousel-caption h5 {
    font-size: 40px;
  }
  .carousel .carousel-inner .carousel-caption p {
    width: 90%;
    font-size: 20px;
  }
  .carousel .carousel-inner .carousel-caption a {
    font-size: 18px;
    padding: 0.7rem 1.4rem;
    margin-top: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .carousel {
    height: 85vh;
  }
  .carousel .carousel-inner .carousel-caption {
    bottom: 150px;
  }
  .carousel .carousel-inner .carousel-caption h5 {
    font-size: 45px;
  }
  .carousel .carousel-inner .carousel-caption p {
    width: 90%;
    font-size: 20px;
  }
  .carousel .carousel-inner .carousel-caption a {
    font-size: 20px;
    padding: 0.7rem 1.4rem;
    margin-top: 30px;
  }
}
@media screen and (min-width: 1440px) {
  .carousel {
    height: 90vh;
  }
  .carousel .carousel-inner .carousel-caption {
    bottom: 210px;
  }
  .carousel .carousel-inner .carousel-caption h5 {
    font-size: 60px;
  }
  .carousel .carousel-inner .carousel-caption p {
    width: 90%;
    font-size: 25px;
  }
  .carousel .carousel-inner .carousel-caption a {
    font-size: 22px;
    padding: 0.7rem 1.8rem;
    margin-top: 40px;
  }
}/*# sourceMappingURL=carousel.css.map */
.admissionProcessPage{
    padding: 1rem 2rem;
    margin-bottom: 2rem;

    .pageTitle{
        font-size: 18px;
    }

    .titleSection{
        width: 100%;
        text-align: center;
        padding: 1rem;

        .subTitle{
            font-size: 15px;
        }
        .subHeading{
            font-size: 12px;
        }
    }

    .sectionContentDiv{
        text-align: justify;
        .sectionContent{
            margin-top: 10px;
            font-size: 12px;
            margin-bottom: 10px;

            span{
                font-weight: 600;
            }
        }

        .contentTitle{
            font-size: 15px;
            font-weight: 600;
        }

        .contentList{
            margin-left: 1.5rem;
            margin-bottom: 10px;
            .listItem{
                font-size: 12px;
                margin-top: 5px;
                margin-bottom: 5px;
                list-style-type: circle;
            }
        }
    }
}

@media screen and (min-width: 1024px){
    .admissionProcessPage{
        padding: 3rem 6rem;

        .pageTitle{
            font-size: 30px;
        }

        .titleSection{
            .subTitle{
                font-size: 20px;
            }
            .subHeading{
                font-size: 18px;
            }
        }

        .sectionContentDiv{
            .sectionContent{
                font-size: 20px;
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .contentTitle{
                font-size: 22px;
            }

            .contentList{
                margin-left: 3rem;
                margin-bottom: 30px;
                .listItem{
                    font-size: 18px;
                }
            }
        }
    }
}
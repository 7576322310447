/*Default colors and styling applied to all the sections*/
:root {
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;
  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);
  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.mainDiv {
  padding: 2rem;
  width: 100%;
}
.mainDiv .section-title span {
  font-size: 15px;
  font-weight: 600;
  color: var(--maroonColor);
}
.mainDiv .section-title .title {
  letter-spacing: 0.5px;
  font-size: 25px;
  line-height: 1.5;
  font-weight: 600;
}
.mainDiv .textContent {
  padding-top: 1rem;
  text-align: justify;
}
.mainDiv .textContent .textPara {
  font-size: 12px;
  margin-bottom: 1rem;
  letter-spacing: 1px;
}

@media screen and (min-width: 600px) {
  .mainDiv {
    padding: 2rem 3rem;
  }
  .mainDiv .section-title span {
    font-size: 16px;
  }
  .mainDiv .section-title .title {
    font-size: 30px;
  }
  .mainDiv .textContent .textPara {
    font-size: 15px;
  }
}
@media screen and (min-width: 840px) {
  .mainDiv {
    padding: 3rem 4rem;
  }
  .mainDiv .section-title span {
    font-size: 18px;
  }
  .mainDiv .section-title .title {
    font-size: 30px;
  }
  .mainDiv .textContent .textPara {
    font-size: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .mainDiv {
    padding: 3rem 6rem;
  }
  .mainDiv .section-title span {
    font-size: 20px;
  }
  .mainDiv .section-title .title {
    font-size: 35px;
  }
  .mainDiv .textContent .textPara {
    font-size: 18px;
  }
}
@media screen and (min-width: 1440px) {
  .mainDiv {
    padding: 3rem 10rem;
  }
  .mainDiv .section-title span {
    font-size: 25px;
  }
  .mainDiv .section-title .title {
    font-size: 45px;
  }
  .mainDiv .textContent .textPara {
    font-size: 20px;
  }
}/*# sourceMappingURL=main.css.map */
/*Default colors and styling applied to all the sections*/
:root {
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;
  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);
  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.galleryDiv {
  padding: 1rem 2rem;
  width: 100%;
}
.galleryDiv .pageTitle {
  font-size: 1rem;
}
.galleryDiv .galleryList {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0;
  padding-left: 0;
}
.galleryDiv .galleryList .listItem .listItemDiv {
  padding: 0.3rem;
}
.galleryDiv .galleryList .listItem .listItemDiv .itemTitle {
  font-size: 0.7rem;
  margin-bottom: 0;
}
.galleryDiv .galleryList .listItem .listItemDiv:hover {
  border-left: 2px solid var(--maroonColor);
  background: var(--lightCreamColor);
}

@media screen and (min-width: 600px) {
  .galleryDiv {
    padding: 1rem 3rem;
  }
}
@media screen and (min-width: 768px) {
  .galleryDiv {
    padding: 1rem 4rem;
  }
  .galleryDiv .pageTitle {
    font-size: 1.5rem;
  }
  .galleryDiv .galleryList {
    margin-top: 1rem;
  }
  .galleryDiv .galleryList .listItem .listItemDiv .itemTitle {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 1024px) {
  .galleryDiv {
    padding: 1rem 6rem;
  }
  .galleryDiv .pageTitle {
    font-size: 1.5rem;
  }
  .galleryDiv .galleryList {
    margin-top: 1rem;
  }
  .galleryDiv .galleryList .listItem .listItemDiv .itemTitle {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 1440px) {
  .galleryDiv {
    padding: 1rem 10rem;
  }
  .galleryDiv .pageTitle {
    font-size: 1.5rem;
  }
  .galleryDiv .galleryList {
    margin-top: 1rem;
  }
  .galleryDiv .galleryList .listItem .listItemDiv .itemTitle {
    font-size: 0.9rem;
  }
}/*# sourceMappingURL=gallery.css.map */
/*Default colors and styling applied to all the sections*/
:root{
    --redColor: #DC0000;
    --maroonColor: #850000;
    --creamColor: #FFDB89;
    --lightCreamColor: #FFF6C3;
  
  
    --darkColor: #0A2647;
    --hoverLightColor: #144272;
    --primaryColor: hsl(199,100%,33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, #2C74B3, #144272);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 11%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(163, 163, 163);
    --inputColor: rgb(239, 239, 239);
    --cardBG: rgb(225, 225, 235);
  
    --blueColor: #22577a;
    --tealColor: #38a3a5;
    --greenColor: #57cc99;
    --lightGreenColor: #80ed99;
    --lightColor: #c7f9cc;
}

.grievanceSectionPage{
    width: 100%;
    padding: 1rem 2rem 3rem;

    .pageTitle{
        font-size: 20px;
        font-weight: 600;
    }

    .contentSection{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .leftSection{
            width: 100%;
            .sectionSubTitle{
                font-size: 15px;
            }
            .contentTable{
                width: 100%;

                .tableDivSection{
                    width: 100%;
                    display: flex;
                    .section{
                        width: 50%;
                        font-size: 12px;
                        padding: .5rem 0;
                        .sectionContent{
                            width: 100%;
                            margin-bottom: 0;
                            span{
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }

        .rightSection{
            width: 100%;
            text-align: center;

            .sectionSubTitle{
                font-size: 15px;
            }

            .contactForm{
                width: 100%;
                padding: 2rem;
                text-align: left;
                background: var(--lightCreamColor);

                .label{
                    margin-bottom: 2px;
                    font-size: 12px;
                    font-weight: 500;
                }
                input, textarea{
                    margin-bottom: .8rem;
                    width: 100%;
                    padding: .3rem;
                    font-size: 10px;
                    border: 1px solid var(--redColor);
                    background: var(--lightCreamColor);
                }

                .formBtn{
                    border: none;
                    font-size: 10px;
                    padding: .5rem;
                    background: var(--maroonColor);
                    color: var(--lightCreamColor);
                }
            }
        }
    }
}

@media screen and (min-width: 600px){
    .grievanceSectionPage{
        padding: 2rem 3rem 4rem;

        .pageTitle{
            font-size: 25px;
            margin-bottom: 2rem;
        }

        .contentSection{
            gap: 3rem;

            .leftSection{
                .sectionSubTitle{
                    font-size: 18px;
                    margin-bottom: 1rem;
                }
                .contentTable{
                    .tableDivSection{
                        .section{
                            font-size: 15px;
                        }
                    }
                }
            }

            .rightSection{
                .sectionSubTitle{
                    font-size: 18px;
                }

                .contactForm{
                    padding: 2rem 3rem;

                    .label{
                        font-size: 15px;
                    }
                    input, textarea{
                        padding: .4rem;
                        font-size: 12px;
                    }
                    .formBtn{
                        font-size: 12px;
                        padding: .5rem .8rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 840px){
    .grievanceSectionPage{
        padding: 2rem 4rem 4rem;

        .pageTitle{
            font-size: 30px;
            margin-bottom: .5rem;
        }

        hr{
            margin-bottom: 2rem;
        }

        .contentSection{
            gap: 3rem;

            .leftSection{
                .sectionSubTitle{
                    font-size: 20px;
                    margin-bottom: 1.5rem;
                }
                .contentTable{
                    .tableDivSection{
                        .section{
                            font-size: 16px;
                        }
                    }
                }
            }

            .rightSection{
                .sectionSubTitle{
                    font-size: 20px;
                }

                .contactForm{
                    padding: 2rem 4rem;

                    .label{
                        font-size: 16px;
                    }
                    input, textarea{
                        padding: .5rem;
                        font-size: 13px;
                        margin-bottom: 1.5rem;
                    }
                    .formBtn{
                        font-size: 14px;
                        padding: .5rem 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px){
    .grievanceSectionPage{
        padding: 2rem 6rem 4rem;

        .pageTitle{
            font-size: 30px;
            margin-bottom: .5rem;
        }

        hr{
            margin-bottom: 3rem;
        }

        .contentSection{
            gap: 3rem;
            flex-direction: row;

            .leftSection{
                .sectionSubTitle{
                    font-size: 18px;
                    margin-bottom: 2rem;
                }
                .contentTable{
                    .tableDivSection{
                        .section{
                            font-size: 15px;
                        }
                    }
                }
            }

            .rightSection{
                .sectionSubTitle{
                    font-size: 18px;
                }
                hr{
                    margin-bottom: 0;
                }

                .contactForm{
                    padding: 2rem 4rem;

                    .label{
                        font-size: 16px;
                    }
                    input, textarea{
                        padding: .5rem;
                        font-size: 13px;
                        margin-bottom: 1.5rem;
                    }
                    .formBtn{
                        font-size: 14px;
                        padding: .5rem 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1440px){
    .grievanceSectionPage{
        padding: 2rem 10rem 4rem;

        .pageTitle{
            font-size: 30px;
            margin-bottom: .5rem;
        }

        hr{
            margin-bottom: 3rem;
        }

        .contentSection{
            gap: 3rem;
            flex-direction: row;

            .leftSection{
                .sectionSubTitle{
                    font-size: 20px;
                    margin-bottom: 2rem;
                }
                .contentTable{
                    .tableDivSection{
                        .section{
                            font-size: 16px;
                        }
                    }
                }
            }

            .rightSection{
                .sectionSubTitle{
                    font-size: 20px;
                }
                hr{
                    margin-bottom: 0;
                }

                .contactForm{
                    padding: 2rem 4rem;

                    .label{
                        font-size: 16px;
                    }
                    input, textarea{
                        padding: .5rem;
                        font-size: 13px;
                        margin-bottom: 1.5rem;
                    }
                    .formBtn{
                        font-size: 14px;
                        padding: .5rem 1rem;
                    }
                }
            }
        }
    }
}
.facilitiesBase {
  padding: 1rem 2rem;
  width: 100%;
}
.facilitiesBase .pageTitle {
  font-size: 1rem;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .facilitiesBase {
    padding: 2rem 4rem;
  }
  .facilitiesBase .pageTitle {
    margin-top: 10px;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .facilitiesBase {
    padding: 2rem 8rem;
  }
  .facilitiesBase .pageTitle {
    margin-top: 10px;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1440px) {
  .facilitiesBase {
    padding: 3rem 10rem;
  }
  .facilitiesBase .pageTitle {
    margin-top: 10px;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
}/*# sourceMappingURL=facilities.css.map */
/*Default colors and styling applied to all the sections*/
:root{
    --primaryColor: hsl(199,100%,33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187,85%,43%), hsl(199,100%,33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 11%);
    --textColor: hsl(240, 28%, 24%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(163, 163, 163);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --cardBG: rgb(225, 225, 235);
    --darkGreenColor: #3C6255;
    --creamColor: #EAE7B1;
  }

.footerDiv{
    justify-content: space-between;
    width: 100%;
    background: var(--blackColor);
    padding: 2rem;


    .footerContent{
        color: var(--whiteColor);
        font-size: 10px;
        margin-bottom: 0;
    }
}

@media screen and (min-width: 600px){
    .footerDiv{
        padding: 2rem 3rem;
        .footerContent{
            font-size: 12px;
        }
    }
}

@media screen and (min-width: 840px){
    .footerDiv{
        padding: 2rem 4rem;
        .footerContent{
            font-size: 12px;
        }
    }
}

@media screen and (min-width: 1024px){
    .footerDiv{
        padding: 2rem 6rem;
        .footerContent{
            font-size: 12px;
        }
    }
}

@media screen and (min-width: 1440px){
    .footerDiv{
        padding: 2rem 10rem;
        .footerContent{
            font-size: 15px;
        }
    }
}
/*Default colors and styling applied to all the sections*/
:root {
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;
  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);
  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.mentorPage {
  width: 100%;
  padding: 1rem 2rem 3rem;
}
.mentorPage .pageTitle {
  font-size: 20px;
  font-weight: 600;
}
.mentorPage .mentorTable {
  width: 100%;
}
.mentorPage .mentorTable .mentorDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.mentorPage .mentorTable .mentorDiv .leftSection {
  width: 100%;
  padding: 0.5rem;
  height: 40vh;
  overflow: hidden;
}
.mentorPage .mentorTable .mentorDiv .leftSection .mentorImg {
  width: 100%;
  height: 100%;
}
.mentorPage .mentorTable .mentorDiv .rightSection {
  width: 100%;
  padding: 0.5rem;
  text-align: justify;
}
.mentorPage .mentorTable .mentorDiv .rightSection .post {
  font-size: 18px;
  font-weight: 600;
}
.mentorPage .mentorTable .mentorDiv .rightSection .mentorText {
  font-size: 15px;
}
.mentorPage .mentorTable .mentorDiv .rightSection .mentorText span {
  font-weight: 600;
}

@media screen and (min-width: 600px) {
  .mentorPage {
    padding: 2rem 3rem 3rem;
  }
  .mentorPage .pageTitle {
    font-size: 25px;
  }
  .mentorPage .mentorTable .mentorDiv {
    flex-direction: row;
  }
  .mentorPage .mentorTable .mentorDiv .leftSection {
    height: 30vh;
    width: 35%;
  }
  .mentorPage .mentorTable .mentorDiv .rightSection {
    width: 65%;
  }
  .mentorPage .mentorTable .mentorDiv .rightSection .mentorText {
    font-size: 12px;
  }
}
@media screen and (min-width: 840px) {
  .mentorPage {
    padding: 2rem 4rem 3rem;
  }
  .mentorPage .pageTitle {
    font-size: 30px;
  }
  .mentorPage .mentorTable .mentorDiv {
    flex-direction: row;
  }
  .mentorPage .mentorTable .mentorDiv .leftSection {
    height: 30vh;
    width: 30%;
  }
  .mentorPage .mentorTable .mentorDiv .rightSection {
    width: 70%;
  }
  .mentorPage .mentorTable .mentorDiv .rightSection .mentorText {
    font-size: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .mentorPage {
    padding: 2rem 6rem 3rem;
  }
  .mentorPage .pageTitle {
    font-size: 35px;
  }
  .mentorPage .mentorTable .mentorDiv {
    flex-direction: row;
  }
  .mentorPage .mentorTable .mentorDiv .leftSection {
    height: 33vh;
    width: 25%;
  }
  .mentorPage .mentorTable .mentorDiv .rightSection {
    width: 75%;
  }
  .mentorPage .mentorTable .mentorDiv .rightSection .post {
    font-size: 20px;
  }
  .mentorPage .mentorTable .mentorDiv .rightSection .mentorText {
    font-size: 15px;
  }
}
@media screen and (min-width: 1440px) {
  .mentorPage {
    padding: 2rem 10rem 4rem;
  }
  .mentorPage .pageTitle {
    font-size: 40px;
  }
  .mentorPage .mentorTable .mentorDiv {
    flex-direction: row;
  }
  .mentorPage .mentorTable .mentorDiv .leftSection {
    height: 33vh;
    width: 20%;
  }
  .mentorPage .mentorTable .mentorDiv .rightSection {
    width: 80%;
  }
  .mentorPage .mentorTable .mentorDiv .rightSection .post {
    font-size: 25px;
  }
  .mentorPage .mentorTable .mentorDiv .rightSection .mentorText {
    font-size: 18px;
  }
}/*# sourceMappingURL=mentor.css.map */
.galleryLook{
    padding-top: 1rem;
    width: 100%;

    .imgGridDiv{
        display: grid;
        gap: .5rem;
        width: 100%;

        .imgDiv{
            width: 100%;
            height: 40vh;
            overflow: hidden;

            .image{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media screen and (min-width: 500px) {
    .galleryLook{
        padding: 1rem 1.5rem;
    
        .imgGridDiv{
            gap: 1rem;
            grid-template-columns: repeat(2, 1fr);
    
            .imgDiv{
                width: 100%;
                height: 50vh;
                overflow: hidden;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .galleryLook{
        .imgGridDiv{
            grid-template-columns: repeat(3, 1fr);

            .imgDiv{
                height: 50vh;
            }

        }
    }
}

@media screen and (min-width: 1024px) {
    .galleryLook{
        .imgGridDiv{
            grid-template-columns: repeat(4, 1fr);

            .imgDiv{
                height: 30vh;
            }

        }
    }
}
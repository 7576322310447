.galleryLook {
  padding-top: 1rem;
  width: 100%;
}
.galleryLook .imgGridDiv {
  display: grid;
  gap: 0.5rem;
  width: 100%;
}
.galleryLook .imgGridDiv .imgDiv {
  width: 100%;
  height: 40vh;
  overflow: hidden;
}
.galleryLook .imgGridDiv .imgDiv .image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (min-width: 500px) {
  .galleryLook {
    padding: 1rem 1.5rem;
  }
  .galleryLook .imgGridDiv {
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .galleryLook .imgGridDiv .imgDiv {
    width: 100%;
    height: 50vh;
    overflow: hidden;
  }
}
@media screen and (min-width: 768px) {
  .galleryLook .imgGridDiv {
    grid-template-columns: repeat(3, 1fr);
  }
  .galleryLook .imgGridDiv .imgDiv {
    height: 50vh;
  }
}
@media screen and (min-width: 1024px) {
  .galleryLook .imgGridDiv {
    grid-template-columns: repeat(4, 1fr);
  }
  .galleryLook .imgGridDiv .imgDiv {
    height: 30vh;
  }
}/*# sourceMappingURL=galleryDesign.css.map */

/*Default colors and styling applied to all the sections*/
:root{
    --redColor: #DC0000;
    --maroonColor: #850000;
    --creamColor: #FFDB89;
    --lightCreamColor: #FFF6C3;
  
  
    --darkColor: #0A2647;
    --hoverLightColor: #144272;
    --primaryColor: hsl(199,100%,33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, #2C74B3, #144272);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 11%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(163, 163, 163);
    --inputColor: rgb(239, 239, 239);
    --cardBG: rgb(225, 225, 235);
  
    --blueColor: #22577a;
    --tealColor: #38a3a5;
    --greenColor: #57cc99;
    --lightGreenColor: #80ed99;
    --lightColor: #c7f9cc;
  }
  

.topbarBox{
    z-index: 1000000;
    top: 0;
    width: 100%;
    justify-content: space-between;
    padding: 1rem 2rem;
    position: sticky;
    background-color: var(--whiteColor);
    transition: background-color 0.4s ease-in-out;

    .left-section{
        .searchBox{
            .icon{
                font-size: 30px;
                color: var(--maroonColor);
            }
        }
    }

    @media screen and (max-width: 1040px){

        .right-section{
        
            .navbarDiv{
                z-index: 1000;
                height: 100vh;
                flex-direction: column;
                background: var(--lightCreamColor);
                padding: 2rem 4rem 5rem;
                gap: 2rem;
                position: absolute;
                right: 0;
                top: 0;
                text-align: center;
    
                .navList{
                    margin-top: 3rem;
                    flex-direction: column;
                    color: var(--maroonColor);
                    font-size: 15px;
                    gap: 1.1rem;
                    letter-spacing: 2px;
                    font-weight: 700;
                    margin-bottom: 0;
                    padding-left: 0;

                    .navItem{
                        .menuIcon{
                            font-size: 25px;
                        }

                        &:hover{
                            .menuIcon{
                                transform: rotate(-90deg);
                                transition: ease-out 0.5s;
                            }
                        }
                    }
                }
    
                .loginBtn{
                    padding: .8rem 2.7rem;
                    background: var(--maroonColor);
                    .loginText{
                        color: var(--whiteColor);
                        font-size: 1rem;
                        font-weight: 500;
                        letter-spacing: 2.5px;
                        margin-bottom: 0;
                    }
    
                    // &:hover{
                    //     background: var(--whiteColorDream);
                    //     .loginText{
                    //         color: var(--blackColor);
                    //     }
                    // }
                }
            }
    
            .navbarDiv.hidden{
                display: none;
            }
            .navbarDiv.extend{
                transform: translate();
                transition: ease-out 0.5s;
                right: 100px;
            }
            .navbarDiv.back{
                transform: translate();
                transition: ease-out 0.5s;
                right: 0;
            }
    
            .hamburger-icon{
                z-index: 10000;
    
                .stick{
                    width: 30px;
                    margin-bottom: 5px;
                    height: 4.5px;
                    background: var(--maroonColor);
    
                    &:nth-child(1){
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                    }
                    &:nth-child(3){
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
    
                            /* ----- animations--------- */
                /* clicked */
                .stick.clicked:nth-child(1){
                    transform: rotate(45deg) translate(0.65em, 1.25em);
                    transition: ease-out 0.5s;
                    background: var(--maroonColor);
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
    
                .stick.clicked:nth-child(2){
                    transform: scale(0);
                    transition: ease-out 0.5s;
                    background: var(--maroonColor);
                }
    
                .stick.clicked:nth-child(3){
                    transform: rotate(135deg) translate(-.4em, 1em);
                    transition: ease-out 0.5s;
                    background: var(--maroonColor);
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
    
                /* unclicked */
                .stick.unclicked {
                    transform: rotate(0) translate(0);
                    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
                }
            }
        }
    }
}

.topbarBox.scrolled{
    background-color: var(--maroonColor);
    opacity: 0.98;

    .left-section{
        .searchBox{
            .icon{
                color: var(--whiteColor);
            }
        }
    }

    .right-section{
        .hamburger-icon{
            .stick{
                background: var(--whiteColor);
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .topbarBox{
        padding: 1rem 3rem;
    }
}

@media screen and (min-width: 840px) {
    .topbarBox{
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

@media screen and (min-width: 1041px){
    .topbarBox{
        padding: 1.5rem 6rem;
        top: -2px;

        .right-section{
            gap: 2rem;

            .searchBox{
                display: none;
            }

            .navbarDiv{
                flex-direction: row;
                gap: 1.2rem;

                .navList{
                    flex-direction: row;
                    gap: 1.5rem;
                    color: var(--maroonColor);
                    margin-bottom: 0;
                    .navItem{
                        font-size: .9rem;
                        font-weight: 500;
                        letter-spacing: .1px;
                    }
                }
                .loginBtn{
                    padding: .5rem 1rem;
                    background: var(--maroonColor);
                    .loginText{
                        font-weight: 600;
                        color: var(--whiteColor);
                        font-size: 1rem;
                        letter-spacing: 1.5px;
                        margin-bottom: 0;
                    }
                }
            }

            .hamburger-icon{
                display: none;
            }
        }
    }

    .topbarBox.scrolled{
        .right-section{
            .navbarDiv{
                .navList{
                    color: var(--whiteColor);
                }
                .loginBtn{
                    background: var(--whiteColor);
                    .loginText{
                        color: var(--maroonColor);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1440px){
    .topbarBox{
        padding: 1.5rem 10rem;

        .right-section{
            gap: 2rem;

            .searchBox{
                display: none;
            }

            .navbarDiv{
                flex-direction: row;
                gap: 1.5rem;

                .navList{
                    flex-direction: row;
                    gap: 1.8rem;
                    margin-bottom: 0;
                    .navItem{
                        font-size: 1rem;
                        font-weight: 400;
                        letter-spacing: .1px;
                    }
                }
                .loginBtn{
                    padding: .6rem 1.2rem;
                    .loginText{
                        font-size: 1rem;
                        font-weight: 600;
                        letter-spacing: 1.5px;
                        margin-bottom: 0;
                    }
                }
            }

            .hamburger-icon{
                display: none;
            }
        }
    }
}


//dropdown menu styles
.topbarBox{
    .right-section{
        @media screen and (max-width: 1040px) {
            .subNavbarDiv{
                z-index: 1000;
                width: 150px;
                height: 100vh;
                flex-direction: column;
                background: var(--maroonColor);
                padding: 5rem 2rem;
                gap: 2rem;
                position: absolute;
                right: 0;
                top: 0;
                text-align: center;

                .subHamburger{
                    z-index: 5000;
                    position: absolute;
                    right: 0;
        
                    .stick{
                        width: 20px;
                        margin-bottom: 5px;
                        height: 3px;
                        background: var(--creamColor);
        
                    }
        
                    /* ----- animations--------- */
                    /* clicked */
                    .stick:nth-child(1){
                        transform: rotate(45deg) translate(-0.25em, .75em);
                        transition: ease-out 0.5s;
                    }
        
                    .stick:nth-child(2){
                        transform: scale(0);
                        transition: ease-out 0.5s;
                        background: none;
                    }
        
                    .stick:nth-child(3){
                        transform: rotate(135deg) translate(0, 1em);
                        transition: ease-out 0.5s;
                    }
                }
            
                .navList{
                    margin-top: 3rem;
                    flex-direction: column;
                    color: var(--lightCreamColor);
                    font-size: 12px;
                    gap: 1.1rem;
                    letter-spacing: 2px;
                    font-weight: 500;
                    margin-bottom: 0;
                    padding-left: 0;
                    text-transform: uppercase;
                }
        
            }
            .subNavbarDiv.hidden{
                display: none;
            }
        }
    }
}

@media screen and (min-width: 1041px){
    .topbarBox{
        .right-section{
            .subNavbarDiv{
                display: none;
            }
        }
    }
}


//web drop down styles
@media screen and (max-width:1040px){
    .topbarBox{
        .right-section{
            .navbarDiv{
                .navList{
                    .navItem{
                        .dropDownListWeb{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.topbarBox{
    .right-section{
        .navbarDiv{
            .navList{
                .navItem{
                    .dropDownListWeb{
                        position: absolute;
                        top: 0;
                        margin-top: 80px;
                        background: var(--lightCreamColor);
                        padding: 1.5rem 0;
                        .listItemDiv{
                            padding: .375rem 1.5rem;
                            .navItem{
                                font-size: 15px;
                                font-weight: 500;
                                text-transform: uppercase;
                                transform: translateY();
                                .icon{
                                    color: var(--maroonColor);
                                    font-size: 20px;
                                }
                                .link{
                                    text-decoration: none;
                                }
                                .color{
                                    color: var(--maroonColor);
                                }
                            }

                            &:hover{
                                transform: translateX(7px);
                            }
                        }
                    }
                    .dropDownListWeb.hidden{
                        display: none;
                    }
                }
            }
        }
    }
}


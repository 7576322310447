/*Default colors and styling applied to all the sections*/
:root{
    --redColor: #DC0000;
    --maroonColor: #850000;
    --creamColor: #FFDB89;
    --lightCreamColor: #FFF6C3;
  
  
    --darkColor: #0A2647;
    --hoverLightColor: #144272;
    --primaryColor: hsl(199,100%,33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, #2C74B3, #144272);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 11%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(163, 163, 163);
    --inputColor: rgb(239, 239, 239);
    --cardBG: rgb(225, 225, 235);
  
    --blueColor: #22577a;
    --tealColor: #38a3a5;
    --greenColor: #57cc99;
    --lightGreenColor: #80ed99;
    --lightColor: #c7f9cc;
}

.headerSectionDiv{
    width: 100%;
    padding: 2rem;

    .section-main-title{
        font-size: 20px;
        font-weight: 600;
        color: var(--maroonColor);
        margin-bottom: 2rem;
    }

    .headerDiv{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 3rem;

    .header-section{
        flex-direction: column;
        box-shadow: 0 0 4px .5px var(--greyText);
        padding: 1rem;
        border-radius: 16px;


        .imgDiv{
            margin-bottom: 1rem;
            width: 100%;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            height: 30vh;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .textDiv{
            line-height: 2;
            .header-title{
                font-size: 18px;
                color: var(--maroonColor);
                font-weight: 600;
            }
            .headerContent{
                text-align: justify;
                font-size: 12px;
            }
        }
    }
}
}

@media screen and (min-width: 600px){
    .headerSectionDiv{
        padding: 2rem 3rem;

        .section-main-title{
            font-size: 25px;
        }
        .headerDiv{
            gap: 2rem;

            .header-section{
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                padding: 1.5rem;
                // height: 40vh;

                .imgDiv{
                    width: 50%;
                    margin-bottom: 0;
                    height: 40vh;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 8px;
                }

                .textDiv{
                    width: 50%;
                    letter-spacing: 1px;

                    .header-title{
                        font-size: 20px;
                        margin-bottom: 1rem;
                    }

                    .headerContent{
                        font-size: 13px;
                        line-height: 1.7;
                    }
                }

                &:nth-child(1){
                    .textDiv{
                        padding-left: 20px;
                    }
                }

                &:nth-child(2){
                    .imgDiv{
                        order: 2;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                    .textDiv{
                        padding-right: 20px;
                    }
                }

                &:nth-child(3){
                    .textDiv{
                        padding-left: 20px;
                    }
                }

            }
        }
    }
}

@media screen and (min-width: 840px){
    .headerSectionDiv{
        padding: 3rem 6rem;

        .section-main-title{
            font-size: 30px;
        }
        .headerDiv{
            gap: 2rem;

            .header-section{
                padding: 1.5rem;
                // height: 40vh;

                .imgDiv{
                    width: 50%;
                    margin-bottom: 0;
                    height: 40vh;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 8px;
                }

                .textDiv{
                    width: 50%;
                    letter-spacing: 1px;

                    .header-title{
                        font-size: 25px;
                        margin-bottom: 1rem;
                    }

                    .headerContent{
                        font-size: 15px;
                        line-height: 1.7;
                    }
                }

                &:nth-child(1){
                    .textDiv{
                        padding-left: 20px;
                    }
                }

                &:nth-child(2){
                    .imgDiv{
                        order: 2;
                    }
                    .textDiv{
                        padding-right: 20px;
                    }
                }

                &:nth-child(3){
                    .textDiv{
                        padding-left: 20px;
                    }
                }

            }
        }
    }
}

@media screen and (min-width: 1024px){
    .headerSectionDiv{
        padding: 3rem 8rem;

        .section-main-title{
            font-size: 30px;
            margin-bottom: 2rem;
        }
        .headerDiv{
            gap: 2rem;
            display: flex;
            flex-direction: row;

            .header-section{
                flex-direction: column;
                justify-content:start;
                width: 33.3%;
                padding: 0;
                box-shadow: none;
                border-radius: 0;
                padding: 0;
                gap: 2rem;

                .imgDiv{
                    width: 100%;
                    margin-bottom: 0;
                    height: 30vh;
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                }

                .textDiv{
                    width: 100%;
                    letter-spacing: 1px;

                    .header-title{
                        font-size: 25px;
                        margin-bottom: 1rem;
                    }

                    .headerContent{
                        font-size: 15px;
                        line-height: 1.7;
                    }
                }

                &:nth-child(1){
                    .imgDiv{
                        border-bottom-left-radius: 0;
                    }
                    .textDiv{
                        padding-left: 0;
                    }
                }

                &:nth-child(2){
                    .imgDiv{
                        order: 1;
                        border-bottom-right-radius: 0;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                    .textDiv{
                        order: 2;
                        padding-right: 0;
                    }
                }

                &:nth-child(3){
                    .imgDiv{
                        border-bottom-left-radius: 0;
                    }
                    .textDiv{
                        padding-left: 0;
                    }
                }

            }
        }
    }
}

@media screen and (min-width: 1440px){
    .headerSectionDiv{
        padding: 4rem 10rem;

        .section-main-title{
            font-size: 40px;
            margin-bottom: 3rem;
        }
        .headerDiv{
            gap: 3rem;
            display: flex;
            flex-direction: row;

            .header-section{
                flex-direction: column;
                justify-content:start;
                width: 33.3%;
                padding: 0;
                box-shadow: none;
                border-radius: 0;
                padding: 0;
                gap: 2rem;

                .imgDiv{
                    width: 100%;
                    margin-bottom: 0;
                    height: 30vh;
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                }

                .textDiv{
                    width: 100%;
                    letter-spacing: 1px;

                    .header-title{
                        font-size: 27px;
                        margin-bottom: 1rem;
                    }

                    .headerContent{
                        font-size: 18px;
                        line-height: 1.7;
                    }
                }

                &:nth-child(1){
                    .imgDiv{
                        border-bottom-left-radius: 0;
                    }
                    .textDiv{
                        padding-left: 0;
                    }
                }

                &:nth-child(2){
                    .imgDiv{
                        order: 1;
                        border-bottom-right-radius: 0;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                    .textDiv{
                        order: 2;
                        padding-right: 0;
                    }
                }

                &:nth-child(3){
                    .imgDiv{
                        border-bottom-left-radius: 0;
                    }
                    .textDiv{
                        padding-left: 0;
                    }
                }

            }
        }
    }
}
/*Default colors and styling applied to all the sections*/
:root{
    --redColor: #DC0000;
    --maroonColor: #850000;
    --creamColor: #FFDB89;
    --lightCreamColor: #FFF6C3;
  
  
    --darkColor: #0A2647;
    --hoverLightColor: #144272;
    --primaryColor: hsl(199,100%,33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, #2C74B3, #144272);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 11%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(163, 163, 163);
    --inputColor: rgb(239, 239, 239);
    --cardBG: rgb(225, 225, 235);
  
    --blueColor: #22577a;
    --tealColor: #38a3a5;
    --greenColor: #57cc99;
    --lightGreenColor: #80ed99;
    --lightColor: #c7f9cc;
}

.registerPage{
    width: 100%;

    .formSection{
        width: 70%;
        padding: 2rem 1rem;
        box-shadow: 0 0 10px 1px black;
        margin: 3rem auto;
        border-radius: 16px;

        .pageTitle{
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 2rem;
            text-align: center;
            letter-spacing: 2px;
            span{
                color: var(--maroonColor);
            }
        }

        .loginForm{
            display: flex;
            flex-direction: column;
            width: 100%;

            label{
                font-size: 12px;
                font-weight: 600;
                color: var(--redColor);
            }
            input{
                padding: .4rem .3rem;
                font-size: 10px;
                margin-bottom: 1.2rem;
            }

            .loginBtn{
                font-size: 12px;
                letter-spacing: 1px;
                background: var(--maroonColor);
                color: var(--lightCreamColor);
                border: none;
            }
        }
        .extra{
            width: 100%;
            padding: 1rem;
            text-align: center;
            
            .text{
                font-size: 10px;
                margin-bottom: 0;
                font-weight: 500;
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .registerPage{
        .formSection{
            padding: 2rem 3rem 2rem;
            margin: 2rem auto;
            width: 50%;

            .loginForm{
                label{
                    font-size: 13px;
                }
                input{
                    font-size: 11px;
                    padding: .5rem .35rem;
                }
                .loginBtn{
                    font-size: 15px;
                }
            }
            .extra{
                padding: 1.5rem 1rem 1rem;
                .text{
                    font-size: 11px;
                }
            }
        }
    }
}

@media screen and (min-width: 840px) {
    .registerPage{
        .formSection{
            padding: 2rem 4rem 2rem;
            margin: 2rem auto;
            width: 50%;

            .loginForm{
                label{
                    font-size: 15px;
                }
                input{
                    font-size: 12px;
                    padding: .5rem .35rem;
                }
                .loginBtn{
                    font-size: 15px;
                }
            }
            .extra{
                padding: 2rem 1rem 1rem;
                .text{
                    font-size: 12px;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .registerPage{
        .formSection{

            .pageTitle{
                font-size: 35px;
            }

            padding: 2rem 6rem 2rem;
            margin: 3rem auto;
            width: 40%;

            .loginForm{
                label{
                    font-size: 15px;
                }
                input{
                    font-size: 12px;
                    padding: .5rem .35rem;
                }
                .loginBtn{
                    font-size: 17px;
                }
            }
            .extra{
                padding: 2rem 1rem 1rem;
                .text{
                    font-size: 13px;
                }
            }
        }
    }
}
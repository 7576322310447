.facilitiesBase{
    padding: 1rem 2rem;
    width: 100%;

    .pageTitle{
        font-size: 1rem;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 768px) {
    .facilitiesBase{
        padding: 2rem 4rem;
    
        .pageTitle{
            margin-top: 10px;
            font-size: 1.2rem;
            margin-bottom: 10px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .facilitiesBase{
        padding: 2rem 8rem;
    
        .pageTitle{
            margin-top: 10px;
            font-size: 1.5rem;
            margin-bottom: 10px;
        }
    }
}

@media screen and (min-width: 1440px) {
    .facilitiesBase{
        padding: 3rem 10rem;
    
        .pageTitle{
            margin-top: 10px;
            font-size: 1.5rem;
            margin-bottom: 10px;
        }
    }
}
/*Default colors and styling applied to all the sections*/
:root{
    --redColor: #DC0000;
    --maroonColor: #850000;
    --creamColor: #FFDB89;
    --lightCreamColor: #FFF6C3;
  
  
    --darkColor: #0A2647;
    --hoverLightColor: #144272;
    --primaryColor: hsl(199,100%,33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, #2C74B3, #144272);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 11%);
    --whiteColorDream: hsl(0, 0%, 93%);
    --greyText: rgb(163, 163, 163);
    --inputColor: rgb(239, 239, 239);
    --cardBG: rgb(225, 225, 235);
  
    --blueColor: #22577a;
    --tealColor: #38a3a5;
    --greenColor: #57cc99;
    --lightGreenColor: #80ed99;
    --lightColor: #c7f9cc;
}

.faqPage{
    width: 100%;
    padding: 1.5rem 2rem;
    height: 72vh;

    .pageTitle{
        font-size: 20px;
        font-weight: 600;
    }
    .text{
        font-size: 12px;
        span{
            font-weight: 600;
        }
    }
}

@media screen and (min-width: 600px){
    .faqPage{
        padding: 1.5rem 3rem;

        .pageTitle{
            font-size: 25px;
        }
        .text{
            font-size: 15px;
        }
    }
}

@media screen and (min-width: 840px){
    .faqPage{
        padding: 1.5rem 4rem;

        .pageTitle{
            font-size: 30px;
        }
        .text{
            font-size: 18px;
        }
    }
}

@media screen and (min-width: 1024px){
    .faqPage{
        padding: 2rem 6rem;

        .pageTitle{
            font-size: 35px;
        }
        .text{
            font-size: 18px;
        }
    }
}

@media screen and (min-width: 1440px){
    .faqPage{
        padding: 2rem 10rem;

        .pageTitle{
            font-size: 35px;
        }
        .text{
            font-size: 20px;
        }
    }
}
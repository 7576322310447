.accreditionPage {
  width: 100%;
  padding: 1rem 2rem;
}
.accreditionPage .pageTitle {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.accreditionPage .tabsGridDiv {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 1rem 0;
}
.accreditionPage .tabsGridDiv .eachTab {
  width: 100%;
  padding: 1rem;
  gap: 1rem;
}
.accreditionPage .tabsGridDiv .eachTab .imgDiv {
  margin: auto;
  width: 80%;
  height: 20vh;
  overflow: hidden;
}
.accreditionPage .tabsGridDiv .eachTab .imgDiv .tabImg {
  width: 100%;
  height: 100%;
}
.accreditionPage .tabsGridDiv .eachTab .textArea {
  width: 100%;
  text-align: justify;
  margin-top: 1rem;
}
.accreditionPage .tabsGridDiv .eachTab .textArea .text {
  font-size: 15px;
}
.accreditionPage .tabsGridDiv .eachTab .textArea .text span {
  font-weight: bold;
}

@media screen and (min-width: 600px) {
  .accreditionPage {
    padding: 1.5rem 3rem;
  }
  .accreditionPage .pageTitle {
    font-size: 1.5rem;
  }
  .accreditionPage .tabsGridDiv {
    grid-template-columns: repeat(2, 1fr);
  }
  .accreditionPage .tabsGridDiv .eachTab .imgDiv {
    width: 100%;
  }
}
@media screen and (min-width: 840px) {
  .accreditionPage {
    padding: 1.5rem 4rem;
  }
  .accreditionPage .pageTitle {
    font-size: 1.5rem;
  }
  .accreditionPage .tabsGridDiv {
    grid-template-columns: repeat(2, 1fr);
  }
  .accreditionPage .tabsGridDiv .eachTab .imgDiv {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .accreditionPage {
    padding: 1.5rem 6rem;
  }
  .accreditionPage .pageTitle {
    font-size: 1.5rem;
  }
  .accreditionPage .tabsGridDiv {
    grid-template-columns: repeat(3, 1fr);
  }
  .accreditionPage .tabsGridDiv .eachTab .imgDiv {
    width: 100%;
  }
}
@media screen and (min-width: 1440px) {
  .accreditionPage {
    padding: 1.5rem 10rem;
  }
  .accreditionPage .pageTitle {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .accreditionPage .tabsGridDiv {
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
  .accreditionPage .tabsGridDiv .eachTab .imgDiv {
    width: 100%;
  }
}/*# sourceMappingURL=accredition.css.map */
/*Default colors and styling applied to all the sections*/
:root {
  --redColor: #DC0000;
  --maroonColor: #850000;
  --creamColor: #FFDB89;
  --lightCreamColor: #FFF6C3;
  --darkColor: #0A2647;
  --hoverLightColor: #144272;
  --primaryColor: hsl(199,100%,33%);
  --secondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, #2C74B3, #144272);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 11%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(163, 163, 163);
  --inputColor: rgb(239, 239, 239);
  --cardBG: rgb(225, 225, 235);
  --blueColor: #22577a;
  --tealColor: #38a3a5;
  --greenColor: #57cc99;
  --lightGreenColor: #80ed99;
  --lightColor: #c7f9cc;
}

.loginPage {
  width: 100%;
}
.loginPage .formSection {
  width: 70%;
  padding: 2rem 1rem;
  box-shadow: 0 0 10px 1px black;
  margin: 5rem auto;
  border-radius: 16px;
}
.loginPage .formSection .pageTitle {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
  letter-spacing: 2px;
}
.loginPage .formSection .pageTitle span {
  color: var(--maroonColor);
}
.loginPage .formSection .loginForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.loginPage .formSection .loginForm label {
  font-size: 12px;
  font-weight: 600;
  color: var(--redColor);
}
.loginPage .formSection .loginForm input {
  padding: 0.4rem 0.3rem;
  font-size: 10px;
  margin-bottom: 1.2rem;
}
.loginPage .formSection .loginForm .loginBtn {
  font-size: 12px;
  letter-spacing: 1px;
  background: var(--maroonColor);
  color: var(--lightCreamColor);
  border: none;
}
.loginPage .formSection .extra {
  width: 100%;
  padding: 1rem;
  text-align: center;
}
.loginPage .formSection .extra .text {
  font-size: 10px;
  margin-bottom: 0;
  font-weight: 500;
}

@media screen and (min-width: 600px) {
  .loginPage .formSection {
    padding: 2rem 3rem 2rem;
    margin: 6rem auto;
    width: 50%;
  }
  .loginPage .formSection .loginForm label {
    font-size: 13px;
  }
  .loginPage .formSection .loginForm input {
    font-size: 11px;
    padding: 0.5rem 0.35rem;
  }
  .loginPage .formSection .loginForm .loginBtn {
    font-size: 15px;
  }
  .loginPage .formSection .extra {
    padding: 1.5rem 1rem 1rem;
  }
  .loginPage .formSection .extra .text {
    font-size: 11px;
  }
}
@media screen and (min-width: 840px) {
  .loginPage .formSection {
    padding: 2rem 4rem 2rem;
    margin: 6rem auto;
    width: 50%;
  }
  .loginPage .formSection .loginForm label {
    font-size: 15px;
  }
  .loginPage .formSection .loginForm input {
    font-size: 12px;
    padding: 0.5rem 0.35rem;
  }
  .loginPage .formSection .loginForm .loginBtn {
    font-size: 15px;
  }
  .loginPage .formSection .extra {
    padding: 2rem 1rem 1rem;
  }
  .loginPage .formSection .extra .text {
    font-size: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .loginPage .formSection {
    padding: 2rem 6rem 2rem;
    margin: 6rem auto;
    width: 40%;
  }
  .loginPage .formSection .pageTitle {
    font-size: 35px;
  }
  .loginPage .formSection .loginForm label {
    font-size: 15px;
  }
  .loginPage .formSection .loginForm input {
    font-size: 12px;
    padding: 0.5rem 0.35rem;
  }
  .loginPage .formSection .loginForm .loginBtn {
    font-size: 17px;
  }
  .loginPage .formSection .extra {
    padding: 2rem 1rem 1rem;
  }
  .loginPage .formSection .extra .text {
    font-size: 13px;
  }
}/*# sourceMappingURL=login.css.map */